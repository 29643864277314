/*-----------------mobile------------------*/
@media screen and (min-width: 320px) {
  .conference-flyer {
    width: 70vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .flyer {
    width: 70vw;
    margin-bottom: 1vh;
  }
  .download {
    width: 50%;
    margin-bottom: 5%;
  }
  .flyer-texts p {
    font-weight: 300;
    margin-bottom: 5%;
  }
  .flyer-texts a {
    color: black;
  }
  .flyer-texts {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 480px) {

  .team-item {
    width: 100%;
  }
  /* .team-inner {
    width: 80vw;
  }  */
  .assessment-training-vid {
    width: 95vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .assessment-training-vid h5 {
    width: 95vw;
  }

  /* HOME */
  .specialty-parent {
    display: flex;
    flex-direction: column;
  }
  .nav-img {
    height: 48px;
    width: 48px;
    margin: 0px 40px 40px 0px;
  }
   .group-parent {
  width: 100vw;
  }
  .group-wrapper {
    width: 100vw;
  }
 .group-item {
    width: 80%;
  }
  .group-img1 {
    height: 157.33px;
  }
  .prev-logo {
    position: relative;
    top: 12vh;
    margin: 0px 8px 0px 5px;
    padding: 0px 20px 0px 10px;
    width: 400git px !important;
    /* height: 100px; */
  }
  .footer-outer {
    flex-direction: column;
  }
  .about {
    margin-left: 20px;
    margin-bottom: 20px;
    margin-top: 0;
  }
  .about > p {
    width: 90vw;
    font-size: 16px;
  }
  .contact {
    margin: 0 0 10px 20px;
  }
  .steps-outer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 99%;
  }
  .steps-inner {
    background-color: #e0e0e0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95vw;
    margin-bottom: 50vh;
    border-radius: 20px;
  }
  .step-item {
    background-color: white;
    width: 85vw;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 2%;
    padding: 1%;
    border-radius: 20px;
  }
  .step-icon {
    font-size: 25pt;
    filter: drop-shadow(10px 5px 4px rgba(0, 0, 0, 0.274));
  }
  .step-texts {
    display: flex;
    flex-direction: column;
    width: 75%;
    font-weight: 300;
  }
  #hubspotTY {
    width: 80vw;
    padding: 2%;
  }
  /* -------CORPORATIONS------ */
  .corp-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  } 
  .corp-paragraph {
    width: 90% !important;
    left: 1vw !important;
  }
  .corp-inner > img {
    width: 80%;
    height: auto;
  }
  .corp-paragraph > h1 {
    font-size: 50px;
    position: relative;
    right: 7%;
  }
  .button-parent-corp {
    width: 85%;
  }
  /*----- CORRECTIONAL ----------*/
  .corr-inner > h1 {
    padding-right: 18px;
  }
  .button-parent-corr {
    margin-top: 40%;
  }
  /*----------- MILITARY--------*/
  .mili-inner > img {
    width: 345px;
    height: 345px;
  }
 .h1 {
    font-size: 40px;
  }
  div.mili-paragraph {
    width: 80vw;
    padding-left: 5px;
  }
  .mili-paragraph > p  {
    font-size: 14px;
  }
  .mili-counters {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 1000px;
  }
  .contact-outer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contact-form {
    width: 250px;
    position: relative;
    right: 12px;
  }
  /*----PUBLIC SAFETY----*/
  .fireFighter-img {
   width: 98%;
   position: relative;
   left: 18px;
  }
  .ps-img-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
  }
  .fireTruck2 {
    display: none;
  }
  .fireTruck3 {
    display: none;
  }
  .ps-pros {
    width: 85%;
    color: black;
    border: none;
    margin-bottom: 30px;
    margin-left: 10%;
    position: relative;
    left: 10% !important;
  }
  .ps-pros h4 {
    position: relative;
    bottom: 40px;
  }
  .PS-List {
    font-size: 16px;
  }
  .PS-list-outer {
    display: flex;
    flex-direction: column;
  }
  .fireFighter-img {
    display: none;
  }
  .ps-carousel-container {
    width: 111px;
    position: relative;
    right: 15px;
  }
  /* -- HUMANITARIAN -- */
  .hum-left-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .hum-para-container {
    position: relative;
  }
  .hum-carousel-img-container {
    height: 700px;
    width: 700px;
    position: relative;
    left: 30px !important;
  }
  /* ---- K12 ----*/
  .k12 > p {
    margin-right: 25px;
  }
  .k12-paragraph {
    margin-right: 15px;
  }
  /*--RESOURCES--*/
  .resources {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .airbb {

  width: 300px;
  display: flex;
  flex-direction: column;
  padding-top: 10%;
  padding-bottom: 10%;
    }
    .airbb2 {
  /* height: 500px; */
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 12%;
  padding-bottom: 10%;
    }
  .airbb-texts {
    width: 80% !important;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding-top: 4vh;
  }
  .resourceImg {
    width: 90%;
    height: 180px;
  }
  .a-z-filter {
    display: flex;
    justify-content: center;
  }
  .card-container {
    width: 80% !important;
  }
  /*------- TEAM ------*/
  .team-modal {
    flex-direction: column;
    width: 85% !important;
    left: 8% !important;
    top: 12% !important;
  }
  .team-modal p {
    font-size: 12px;
  }
  .modal-img-container {
    border-right: 1px solid transparent !important;
    width: 105% !important;
    margin-bottom: 10px;
  }
  .exit-btn {
    display: none;
  }
  .col-md-6 {
    padding-left: 0 !important;
  }
  .overlay {
    background-color: transparent !important;
  }
  .overlay p {
    background-color: transparent;
    height: 40px;
    padding-left: 10px;
    color: white;
  }
  .black-font {
    color: black;
  }
/*------FAITH BASED-------*/
.faith-button-parent {
  width: 85%;
  left: 8% !important;
}
.faith-paragraphs-container {
  width: 100%;
}

}
@media screen and (min-width: 481px) {
/* this is for the flyer */


  /*-----HOME-----*/
  .prev-logo {
    position: relative;
    top: 12vh;
    margin: 0px 8px 0px 10px;
    padding: 0px 20px 0px 10px;
    /* width: 300px !important; */
    /* height: 120px !important; */
  }
  /*------RESOURCES-----*/
   .resources {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .airbb {
  width: 300px;
  display: flex;
  flex-direction: column;
  padding-top: 10%;
  padding-bottom: 10%;
    }
    .airbb2 {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 12%;
  padding-bottom: 10%;
    }
  .airbb-texts {
    width: 80% !important;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding-top: 4vh;
  }
  .resourceImg {
    width: 90%;
    height: 180px;
  }
  .a-z-filter {
    display: flex;
    justify-content: center;
  }
  .card-container {
    width: 80% !important;
  }
  /*------PUBLIC SAFETY------*/
  .PS-list-outer {
    display: flex;
    flex-direction: column;
  }
  .ps-pros {
    width: 85%;
    margin-left: 10%;
  }
  .fireFighter-img {
    display: none;
  }
  .steps-outer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .steps-inner {
    background-color: #e0e0e0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95vw;
    margin-bottom: 20vh;
  }
  .step-item {
    background-color: white;
    max-width: 85vw;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 2%;
    padding: 1%;
  }
  .step-icon {
    font-size: 25pt;
  }
  .step-texts {
    display: flex;
    flex-direction: column;
    width: 75%;
    font-weight: 300;
  }

}

@media screen and (min-width: 760px) {
.team-group {
  display: flex;
  justify-content: center !important;
}
  .team-item p {
    width: 350px;
  }
  /* Journey */
  /* .journey-item-dark {
    position: relative;
    left: 10%
  } */
  .journey-item {
    width: 452px;
    /* height: 245px; */
  } 
  .journey-item-dark {
    width: 452px;
    /* height: 245px; */
  }
  .padright{
   font-size: 15px;
  }
  .journey-icon-dark {
    position: relative;
    right: 10px;
  }

  /*UNI FOOTER*/
  .footer-line-mobile {
    background-color: white !important;
  }
  /*  HOME   */
  .prev-logo {
    position: relative;
    /* top: 8vh; */
    margin: 0px 10px 0px 10px;
    padding: 0px 20px 0px 10px;
  }
  /*------------- PUBLIC SAFETY MIN 760-------------- */
  .ps-img-container {
    display: flex;
    align-items: center;
    justify-self: center;
  }
  .fireTruck2 {
    width: 33.3%;
  }
  .PS-list-outer {
    display: flex;
    flex-direction: row;
  }
  .react-multi-carousel-list {
    width: 100%;
  }
  .fireFighter-img {
    display:inline-block;
    width: 50%;
  }
  .ps-pros {
    height: 600px !important;
    font-size: 16px;
    width: 35%;
    
  }
  .PS-list-outer {
    position: relative;
    margin-left: 15px;
  }
  /*  CORPORATIONS */
  .corp-paragraph {
    margin-left: 10px !important;
  }
  /*---HUMANITARIAN--*/
  .hum-carousel-img-container {
    height: 700px;
    width: 700px;
    position: relative;
    left: 60px !important;
  }
  /*--------RESOURCES-------*/
  .resources {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .airbb {
  /* height: 500px; */
  width: 300px;
  display: flex;
  flex-direction: column;
  padding-top: 10%;
  padding-bottom: 10%;
    }
    .airbb2 {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 12%;
  padding-bottom: 10%;
    }
  .airbb-texts {
    width: 80% !important;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding-top: 4vh;
  }
  .resourceImg {
    width: 90%;
    height: 180px;
  }
  .a-z-filter {
    display: flex;
    justify-content: center;
  }
  .card-container {
    width: 80% !important;
  }

  /* ----- TEAM ----*/
  /* .team-modal {
    flex-direction: column;
    width: 40% !important;
    left: 22% !important;
    top: 25% !important;
  }  */
  .team-modal p {
    font-size: 12px;
  }
  .modal-img-container {
    border-right: 1px solid transparent !important;
    width: 105% !important;
    margin-bottom: 10px;
  }
  .exit-btn {
    display: none;
  }
  .col-md-6 {
    padding-left: 0 !important;
  }

  /*------ TIPS ------*/
  .tips-header2 {
      width: 75vw;
    }  
  .tips-header3 {
      width: 75vw;
    }



}
@media screen and (max-width: 768px) {

  /* .team-item {
    width: 45%;
    position: relative;
    right: 10px;
  } */

}
/*-----------------tablet-------------------*/
@media screen and (max-width: 900px ) {
    /* --------UNI FOOTER--------- */
    .uni-footer {
      flex-direction: column;
    }
    .footer-snippet {
      width: 275px !important;
      justify-content: space-between !important;
    }
    .footer-snippet > p {
      text-align: center;
    }
    p.email-hover-dark {
      text-align: center;
    }
    .footer-snippet-larger > p {
      text-align: center;
    }
    .footer-logo {
      width: 100% !important;
    }
    .footer-line {
      background-color: transparent !important;
    }
    .footer-line-mobile {
      width: 100%;
      height: 1px;
      background-color: #cfcfcf;
    }
  /*----------HOME--------------*/
  .nav-img {
    height: 48px;
    width: 48px;
    margin: 0px 40px 10px 0px;
  }
  .background-video {
    position: relative;
  }
  .prev-logo {
    position: relative;
    /* bottom: 10vh; */
    width: 100vw;
  }
  .group-parent {
    position: relative;
    bottom: 30vh;
  }
.group-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
  .specialty-parent {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 80vw;
  }
  .specialty-img {
    width: 100%;
    height: 215px;
  }
  .carouselHome {
    position: relative;
    bottom: 10vh;
  }
  .paragraph > p {
    font-size: 12px;
  }
  .paragraph {
    width: 80vw;
    position: relative;
    right: 5vw;
  }
  .contact > p {
    font-size: 12px;
  }
  /*--------Faith Based----------*/
  .faith-paragraphs-container {
    width: 75vw;
  }
  
  /*----------CORRECTIONAl-------*/
  
  .corr-para { 
    width: 85%;
    top: 10vh;
  }
  .button-parent-corr {
    width: 85%;

    margin-top: 40%;  
  }
  
  /* CORPORATIONS */
  .corp-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .corp-paragraph > p {
    position: relative;
    font-size: 20px;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  .corp-inner > img {
    width: 80%;
    height: auto;
  }
  .corp-outer > h1 {
    font-size: 50px;
  }
  /* MILITARY */
  .mili-paragraph {
    width: 90vw;
    padding-left: 5px;
  }
  .mili-paragraph > p  {
    font-size: 16px;
  }
  /*PUBLIC SAFETY*/
  .fireTruck2 {
    width: 33.3%;
  }
  .fireTruck3 {
    display: none;
  }
  .ps-pros {
    position: relative;
    /* left: 8vw; */
    top: 3vh;
  }
  .ps-pros h4 {
    position: relative;
    bottom: 40px;
  }
  .ps-pros ul {
    position: relative;
    bottom: 40px;
  }
  /*---- TEAM -----*/
  /* .team-modal {
    flex-direction: column;
    width: 60% !important;
    left: 20% !important;
    top: 12% !important;
  } */
  .team-modal p {
    font-size: 12px !important;
  }
  .modal-img-container {
    border-right: 1px solid transparent !important;
    width: 105% !important;
    margin-bottom: 10px;
  }
  .exit-btn {
    display: none;
  }
  .col-md-6 {
    padding-left: 0 !important;
  }
  /*-------------PPN INFO max 900px--------------------*/
  /* .training-videos-container {
    flex-direction: column;
  } */
  .iframe-video {
    width: 80vw !important;
  }

 
  
  /* .ppn-trainings-outer {
    width: 100%;
  }
  .text-right {
    width: 90vw !important;
  }
  .text-right h1 {
    width: 100vw;
    margin-bottom: 5vh;
  }
  .text-right p {
    width: 80vw !important;
  } */

  .image-right-text-left {
    width: 100%;
  }
  .text-left {
    width: 90vw !important;
    right: 0 !important;
  }
  .text-left h1 {
    width: 100vw;
    margin-bottom: 5vh;
  }
  .text-left p {
    width: 80vw !important;
  }


} 
@media screen and (min-width: 901px) {
  .team-modal {
    width: 60% !important;
    left: 20% !important; 
  }
  /*---------HOME ---------*/
  .specialty-parent {
    width: 80vw;
  }
  .corp-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .corp-paragraph > p {
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 20px;
  }
  .corp-inner > img {
    width: 80%;
    height: auto;
  }
  .corp-outer > h1 {
    font-size: 50px;
  }
  /*------PUBLIC SAFETY----*/
  .ps-pros {
    position: relative;
    left: 8vw;
    top: 3vh;
  }

/*-------RESOURCES------*/
  .airbb {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }
  .airbb-texts {
    width: 50% !important;
  }
  .airbb2 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }
  /*------------PPN INFO MIN 901-------------*/
  .text-left {
    right: 30vw;
  }

  /*-----CORRECTIONAL FACILITIES-----*/
  .button-parent-corr {
    left: 12%;
    margin-top: 10%;
  }
  .corr-para {
    top: 10vh;
    width: 85%;
  }
}
@media screen and (max-width: 991px) {
  .contact-btn {
    border: none !important;
  }
  /*-------PPN INFO MAX 991------*/
  .ppn-trainings-outer {
    width: 100%;
  }
  .text-right {
    width: 90vw !important;
  }
  .text-right h1 {
    width: 100vw;
    margin-bottom: 5vh;
  }
  .text-right p {
    width: 80vw !important;
  }
}

/*-----------------laptop / ipad PRO---------*/
@media screen and (min-width: 1023px) {
  .cookie-text {
    width: 50vw;
  }

  .mhm-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 25vh;
  }
  .mhm-signup {
    width: 75%;
    height: 650px;
    /* margin-bottom: 50px; */
  }
  
  .team-group {
    justify-content: flex-start !important;
  }

  /* this is for the flyer */
.conference-flyer {
  width: 50vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flyer {
  background-position: center;
  background-repeat: no-repeat;
  width: 30vw;
}
.download {
  width: 50%;
  margin-bottom: 5%;
}
.flyer-texts p {
  font-weight: 300;
  margin-bottom: 5%;
}
.flyer-texts a {
  color: black;
}
.flyer-texts {
  display: flex;
  flex-direction: column;
  align-items: center;
}

  /*-- HOME-- */
  .journey-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    width: 50%;
  }
  .prev-logo {
    position: relative;
    /* top: 16vh; */
    width: 75%;
    margin: 0px 10px 0px 10px;
    padding: 0px 20px 0px 10px;
  }
  .specialty-parent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 5vh;
    width: 70vw;
  }
  .specialty {
    color: black;
    /* font-size: 15px; */
  }
  .group-wrapper {
    margin: 50px;
  }
  .group-item {
    margin: 25px;
  }
  .steps-outer {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    padding: 5% 0 0 0;
    /* margin-bottom: 40vh; */
    position: relative;
    bottom: 30vh;
  }
  .steps-outer > h1 {
    margin-bottom: 15vh;
  }
  .steps-inner {
    background-color: #e0e0e0;
    width: 90vw;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding: 5vh 0 5vh 0;
    margin-bottom: 0vh;
  }
  .step-item {
    background-color: white;
    width: 45%;
    /* border: 1px solid black; */
    /* border-radius: 10px; */
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 2%;
    padding: 1%;
  }
  .step-icon {
    font-size: 35pt;
    filter: drop-shadow(10px 5px 4px rgba(0, 0, 0, 0.274));
  }
  .step-texts {
    display: flex;
    flex-direction: column;
    /* width: 75%; */
  }
  .step-texts p {
    font-weight: 400;
  }
  /*-- FAITH BASED--*/
  .icon-container {
    display: flex;
    justify-content: left;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 85%;
    position: relative;
    left: 10vw;
  }
  .faith-paragraphs-container {
    width: 75vw;
  }
  .icon {
    padding: 0px 0px 0px 0px;
    width: 350px;
  }
  /*-- CORRECTIONAL-- */
  /* .button-parent-corr {
    margin-top: 15%;
  }  */


  /* --CORPORATIONS --*/
  .corp-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .corp-paragraph > p {
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 20px;
  }
  .corp-inner > img {
    width: 80%;
    height: auto;
  }
  .corp-outer > h1 {
    font-size: 50px;
  }
    /*--MILITARY-- */
    .mili-paragraph {
      width: 90vw;
      padding-left: 5px;
    }
    .mili-paragraph > p  {
      font-size: 20px;
    }
    /*---------PUBLIC SAFETY--------*/
    .PS-list-outer {
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
    }

  .fireTruck2 {
    width: 33.3%;
  }
  .fireFighter-img {
    width: 33.3%;
  }
  .ps-pros {
    position: relative;
    left: 8vw;
    top: 3vh;
    width: 434px;
  }
  .ps-pros h4 {
    position: relative;
    top: 10px;
  }
  .ps-pros ul {
    position: relative;
    top: 10px;
  }
  
  /*--RESOURCES--*/
  .resources {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin-left: 5%;
  }
  .card-container {
    width: 75vw !important;
  }
  .airbb-texts {
    width: 400px !important;
  }
  .airbb {
    width: 90% !important;
    justify-content: space-around;
  }
  .airbb2 {
    width: 90% !important;
    justify-content: space-around;
  }

    /*------ TIPS ------*/
    .tips-header2 {
      width: 50vw;
    }  
  .tips-header3 {
      width: 50vw;
    }
    .safeUt {
      width: 20% !important;
    }
}

@media screen and (max-width: 1200px) {



  /*-----CORPORATIONS-----*/
  .corp-inner {
    flex-direction: column !important;

  }
  .corp-paragraph {
    /* width: 90vw !important; */
    position: relative;
    /* right: 43%; */
    margin-left: 20px !important;
    margin-right: 10px !important;
  }
  .corp-paragraph > p {
    font-size: 16px !important;
    text-align: left;
  }
  /*------PUBLIC SAFETY----*/
  .fireFighter-img {
    position: relative;
    left: 5%;
  }
  .ps-pros {
    position: relative;
    left: 2%;
  }
  /*-------- TEAM --------*/
  .team-modal {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: white;
    color: black;
    /* height: 350px; */
    width: 60%;
    border: none;
    z-index: 2 !important;
    position: fixed;
    top: 35%;
    left: 20%;
    border-radius: 5px;
    box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.15);
    /* animation: fadeIn 0.5s ease-in-out; */
  }
  /*-----------PPN INFO MAX 1200--------------*/
  .training-videos-container {
    flex-direction: column;
  }
  .ppn-trainings-outer {
    width: 100%;
  }
  .text-right {
    width: 75vw !important;
  }
  .text-right h1 {
    width: 80vw;
    margin-bottom: 5vh;
  }
  .text-right p {
    width: 60vw !important;
  } 
  .training-columns {
    flex-direction: column;
    align-items: stretch;
  } 
  .left-img-wrapper, .right-img-wrapper, .left-img-wrapper2 {
    display: none;
  }
  .steps-inner {
    margin-bottom: 15vh;
  }
}

@media screen and (min-width: 1159px) {

  .team-item {
    width: 50%;
  }
 

  .specialty-parent {
    width: 100vw;
  }

}
/*--------------------DESKTOP-----------------*/
@media screen and (min-width: 1201px) {


/* first responder conf flyer home page */
.center-content {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  color: black;
}
.underline {
  color: #7b0303;
}
.flyer-comp-outer {
  width: 75vw;
  display: flex;
  justify-content: space-evenly;
  font-weight: 400;
  font-size: 12pt;

}
.firstrcflyer {
  width: 40%;

}
.flyer-comp-inner {
  width: 40%;
}
.frc-email {
  color: #7b0303 !important;
  font-style: italic !important;
}

    /* this is for the flyer */
.conference-flyer {
  width: 60vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.flyer {
  background-position: center;
  background-repeat: no-repeat;
  width: 25vw;
}
  /*-----HOME --------*/
  .prev-logo {
    position: relative;
    top: 8vh;
    /* height: 400px !important; */
    margin: 0px 10px 0px 10px;
    padding: 0px 20px 0px 10px;
  }
  /*HUMANITARIAN*/
  .hum-outer {
    display: flex;
  }
  .hum-carousel-img {
    width: 700px;
    height: 700px;
  }
  .hum-right-bottom {
    width: 50vw;
  }
  .hum-carousel {
    position: relative;
    top: 10vh;
  }
  .hum-carousel-img-container {
    height: 700px;
    width: 700px;
    position: relative;
    left: 96px;
  }
  .hum-outer {
    padding-bottom: 10vh;
  }
  /* faith based*/
  .icon-container {
    display: flex;
    justify-content: left;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 85%;

    position: relative;
    left: 10vw;
  }
  .faith-paragraphs-container {
    width: 75vw;

  }
  /* CORRECTIONAL */
  .corr-para {
    width: 55vw;
    font-size: 20px;
  }
  .cor-icon {
    margin-top: 50px;
  }
  /* K 12 */
  .k12 {
    width: 60vw;
    margin-left: 20vw;
  }
  .k12-inner > h1 {
    font-size: 60px;
  }
  /* CORPORATIONS */
  .corp-inner > img {
    width: 30% !important;
    height: auto !important;
  }
  /* MILITARY */
  .mili-paragraph {
    width: 90vw;
    padding-left: 5px;
  }
  .mili-paragraph > p  {
    font-size: 20px;
  }
  .button-parent-mili{
    padding-bottom: 40px;
  }
  /*---------PUBLIC SAFETY----------*/

  .fireTruck2 {
    width: 33.3%;
  }
  .Left-inner {
    width: 100%;
  }
  .fireFighter-img {
    width: 33.3%;
  }
  /*---------RESOURCES-------*/
  /* .hrc-inner {
    margin-left: 15% !important;
  } */
  .resources {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
  }
  /* .card-container {
    width: 90vw !important;
  } */
  .airbb-texts {
    width: 600px !important;
  }
  .airbb {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }
  .airbb2 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }
  .tips-container {
    width: 100vw;
  }
  /*----------------ppn info min 1201---------*/
  .training-columns {
    flex-direction: row;
    align-items: flex-start;
  }

}

@media screen and (min-width: 1375px) {
  .team-group {
    width: 80%;
  }
  .team-item {
    width: 33%;
  }

  .specialty-parent {
    width: 90vw;
  }
  .corp-img {
    margin: 20px;
  }

}
/* ------------------------XL------------------*/
@media screen and (min-width: 1500px) {

/* .mhm-form {
  width: 100%;
  height: 700px;
  margin-top: -100px;
} */


/* this is for the flyer */
.conference-flyer {
  width: 45vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.flyer {
  background-position: center;
  background-repeat: no-repeat;
  width: 18vw;
}

  .steps-inner {
    width: 45vw;
    margin-bottom: 10vh;
  }

  .team-modal {
    width: 40% !important;
    left: 30% !important;
  }
  /* HOME */
  .prev-logo {
    height: 400px;
    margin: 0px 10px 90px 10px;
    padding: 0px 20px 0px 10px;
  }
  .background-video {
    margin-top: 10vh;
  }
  .specialty-parent {
    width: 80vw;
  }
 
  /*Faith Based */
  .icon-container {
    display: flex;
    justify-content: left;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 60%;
    /* padding-left: 10%; */
  }
  /*PROFESSIONAL*/
  .pro-container {
    margin: 8vh 10vw 5vh 10vw;
  }
  .pro-container > h1 {
    font-size: 60px;
    font-weight: 500;
  }
  .pro-para {
    width: 40vw;
  }
  /* CORRECTIONAL */
  /* K 12 */
  .k12 {
    width: 60vw;
    margin-left: 20vw;
  }
  .k12-inner > h1 {
    font-size: 60px;
  }
  /* CORPORATIONS */
 
  .corp-inner > img {
    width: 500px !important;
    height: auto !important;
  }
  .corp-paragraph {
    font-size: 18px;
    width: 90vw;
    display: flex
  }
  .corp-paragraph > p {
    text-align: center;
  }
  .corp-outer > h1 {
    font-size: 60px;
  }
  /* MILITARY */
  .mili-paragraph {
    width: 40vw;
    padding-left: 5px;
  }
  .mili-paragraph > p  {
    font-size: 20px;
  }
  .button-parent-mili{
    padding-bottom: 40px;
  }
  /* --CONTACT-- */
  /*--PUBLIC SAFETY --*/
  .fireFighter-img {
    width: 33.3%;
  }
  /*--RESOURCES--*/
  .resources {
    padding: 0 5vw 0 5vw;
  }
  .resources {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
  }
  /* .card-container {
    width: 90vw !important;
  } */
  .airbb-texts {
    width: 600px !important;
  }
  .airbb {
    width: 75% !important;
    justify-content: space-around;
  }
  .airbb2 {
    width: 75% !important;
    justify-content: space-around;
  }

/*------ TIPS ------*/
  .tips-header2 {
    width: 35vw;
  }  
 .tips-header3 {
    width: 35vw;
  }
}
@media screen and (min-width: 1719px) {
  .specialty-parent {
    width: 73vw !important;
  }
  /* .team-item {
    width: 20vw !important;
  } */
}
@media screen and (min-width: 1900px) {
    /* HOME */
    .prev-logo {
      position: relative;
      /* top: 3vh; */
      /* width: 70%; */
      height: 400px !important;
      margin: -60px 10px 90px 10px;
      padding: 0px 20px 0px 10px;
    }
    .specialty-parent {
      width: 60vw !important;
    }
    /*PUBLIC SAFETY*/
    .PS-list-outer {
      width: 75vw;
      position: relative;
      /* left: 4vw; */
      }
      .fireFighter-img {
        width: 33.3%;
      }
      /*----RESOURCES-----*/
      .resources {
        padding: 0 0vw 0 18vw;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
      }
      /* .card-container {
        width: 95vw !important;
      } */
      .line {
        width: 65% !important;
      }
      .airbb-texts {
        width: 600px !important;
      }
      .airbb {
        width: 65% !important;
        justify-content: space-around;
      }
      .airbb2 {
        width: 65% !important;
        justify-content: space-around;
      }
}
/* ------------------ SUPER LARGE ------------ */
@media screen and (min-width: 2000px) {
  /*----HOME--------*/
  .journey-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    width: 45%;
  }
  .prev-logo {
    position: relative;
    top: 3vh;
    width: 50%;
    height: 250px;
    margin: 0px 10px 0px 10px;
    padding: 0px 20px 0px 10px;
  }
  .specialty-parent {
    width: 60vw !important;
  }
   /*------------ PUBLIC SAFETY----------- */
   .PS-Outer {
    display: flex;
    /* justify-content: center;
    align-items: flex-start; */
  }
  .PS-Left {
    width: 50vw;
  }

  .PS-Right {
    width: 50vw;
    height: auto;
    /* overflow: hidden; */
  }
  .fireFighter-img {
    height: auto;
    width: 50vw;
  }
  .PS-Left > h1{
    font-size: 50px;
  }
  .PS-Left > p {
    font-size: 18px;
  }
  .PS-List > li {
    font-size: 18px;
  }
  .button-parent {
    padding-top: 100px;
  }
    /*PUBLIC SAFETY*/
    .fireTruck2 {
      width: 33.3%;
    }
    .fireFighter-img {
      width: 33.3%;
    }
/*---------CORPORATIONS------*/
.corp-paragraph {
  width: 90vw;
}

    /*------ TIPS ------*/
    .tips-header2 {
      width: 35vw;
    }  
  .tips-header3 {
      width: 35vw;
    }
}
