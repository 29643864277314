/* APP.CSS IS ALL THE HOME PAGE STYLES. NOTHING ELSE IS IN THIS STYLESHEET, AND THERE AREN'T HOME PAGE STYLES ELSEWHERE except responsive design*/ 

/* NAV BAR */
nav {
  -webkit-box-shadow: 0px 2px 18px 2px rgba(0,0,0,0.03); 
  box-shadow: 0px 2px 18px 2px rgba(0,0,0,0.03);
}

.navbar-light .navbar-nav .nav-link {
  color: #111921 !important;
  margin-left: 5px;
}
.home-link {
  color: #8f2a2b !important;
}
.nav-link:last-of-type {
  margin-bottom: 3px;
}
.nav-link:hover {
background-color: rgb(241, 240, 240);
}
.dropdown-menu.show {
  border: none;
  animation: easeUp 300ms;
}
.dropdown-menu {
  font-size: 14px;
  line-height: 12pt;
  position: relative;
  top: 20px;
}

.dropdown:hover>.dropdown-menu { 
  display: block;
  color: rgb(15, 15, 15);
  border: none;
  animation: easeDown 300ms ease-in-out forwards;
  transform-origin: top center;
}
@keyframes easeDown {
  0% { transform: scaleY(0)}
  80% {transform: scaleY(0.9)}
  100% {transform: scaleY(1)}
}

/* ul {
  list-style-type: none;
} */

.my-2 {
  margin-top: 0;
}
.nav-wrapper {
  font-size: 12px;
  font-weight: 600;
}
.link { 
  text-decoration: none;
  color: #111921;
}
.link:hover {
  color: #8f2a2b;
  
}
.t-link {
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
  top: 8px;
}
.t-link:hover {
  color: #8f2a2b;
}
.a:hover {
  color: white;
}
.white-hover:hover {
  color: white;
}
.home-link {
  color: #7b0303 !important;
}
.contact-btn {
  border: 1px solid #111921 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding: 5px !important;
  position: relative;
  top: 2px;
  left: 5px;
}
.contact-btn:hover {
  color: #8f2a2b !important;
}

.nav-img {
  height: 48px;
  width: 48px;
}
/* #navbarScroll {
  margin:  40px 0px 40px 750px;
} */
.navbar-wrapper {
position: fixed;
display: flex;
flex-direction: row;
justify-content: space-between;
width: 25vw;
height: 10vh;
background-color: white;
z-index: 1;
}
.navbar-nav-scroll {
  background-color: white;
  max-height: 300px !important;
  position: relative;
  bottom: 20px;
}
.navbar-collapse {
  position: relative;
  top: 21px; 
} 
nav {
  background-color: white;
}
.nav-links {
  position: absolute;
  top: 40px;
  display: flex;
  justify-content: space-around;
  width: 75vw;
  background-color: white;
}

.menu {
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  /* top: 60px;
  right: 0; */
  width: 300px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
}

.menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  z-index: 6;
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  
}

.menu li a {
  text-decoration: none;
  color: #333333;
  padding: 15px 20px;
  display: block;
}
.navbar-spacing {
  display: flex;
  justify-content: space-between;
  z-index: 1;   
  height: 10vh;
}
/* .navbar {
  width: 100vw !important;
} */

#navbarScrollingDropdown {
  color: #333333;
}
/* Cookies */
.cookie-btn {
  background-color: #fff;
  color: #000;
  border: 1px solid white;
  border-radius: 3px;
  font-size: 10pt;
  margin-left: 1%;
}
.cookies-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5vh;
}
.cookie-text {
  background-color: rgba(211, 211, 211, 0.267);
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  border-bottom: 5px;
  border-radius: 10px;
  border-style: solid;
  padding: 1rem;
  border-image: linear-gradient(to right, #7b0303, #cfcfcf) 1 50;
  font-weight: 400;
  font-size: larger;
  margin-bottom: 10vh;
}

/* Journey */
.journey-outer {
  background-color: #7b0303;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10vh;
}
.journey-outer h1 {
  margin-top: 2vh;
  margin-bottom: 2vh;
  color: white;
}
.journey-container{
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5vh;
}
.journey-item {
  width: 306px;
  /* background-color: #85ACDE; */
  background-color: white;
  /* color: white; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  margin: 10px 0 10px 0;
  filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.25));
}
.journey-item-dark {
  width: 306px;
  /* background-color: #0C366D; */
  background-color: white;
  /* color: white; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  margin: 10px 0 10px 0;
  filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.25));
}
.journey-icon {
  font-size: 80px;
  margin: 20px;
}
.journey-icon-dark {
  width: 110px;
}
.journey-texts {
  text-align: left;
  border-left: 1px solid white;
  padding: 20px 0 0 20px;
}
.journey-texts-dark {
  text-align: left;
  border-right: 1px solid white;
  padding: 20px 0px 0 10px;
}
.journey-texts ul {
  font-weight: 400;
}
.journey-texts-dark ul {
  font-weight: 400;
}
/* VIDEO */
.background-video {
  position: relative;
  /* background-color: #111921; */
  height: 75vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
  bottom: 30vh;
}
.center-content {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: black;
  margin-bottom: 30vh;
  margin-left: 5%;
  margin-right: 5%;
}
/* .underline {
  color: #7b0303;
} */
.flyer-comp-outer {
  font-weight: 400;
  font-size: 12pt;
}
.firstrcflyer {
  width: 90%;
}
.flyer-comp-inner {
  width: 100vw;
}
/* .frc-email {
  color: #7b0303 !important;
  font-style: italic !important;
} */

.prev-clip {
  position: absolute;
  margin-top: 70vh;
  /* top: 50%; */
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.logo-wrapper {
  position: relative;
  top: 30vh;
  /* z-index: 1; */
}
/* .prev-logo {
  height: 20vh;
  position: relative;
  
} */

.compH1 {
  position: relative;
  bottom: 30vh;
  margin: 10vh 0 0vh 0;
}
.compH3 {
  
  position: relative;
  bottom: 30vh;
  margin: 3vh 0 10vh 0;
  font-style: italic;
}

/* GROUPS SECTION */
.group-parent {
  background-color: #e9e9e9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 30vh;
  padding-bottom: 10vh;
}
.group-parent > h2 {
  /* padding: 40px; */
  font-size: 24px;
  margin-top: 1%;
}
.group-wrapper {
  width: 80vw;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
}
.group-item {
  width: 400px;
  padding: 10px;
}
.group-item > p{
  font-weight: 400;
}
.group-img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.125);
}

.caption {
  text-align: left;
  line-height: 2;
  font-weight: 400;
}
p {
  text-align: left;
  line-height: 1.8;
}
/* STEP BY STEP */
/* .steps-outer {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  padding: 10% 0;

  position: relative;
  bottom: 30vh;
}
.steps-outer > h1 {
  margin-bottom: 10vh;
}
.steps-inner {
  background-color: #e0e0e0;
  width: 68vw;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 5vh 0 5vh 0;
}
.step-icon {
  font-size: 35pt;
  filter: drop-shadow(10px 5px 4px rgba(0, 0, 0, 0.274));
}
.step-texts {
  display: flex;
  flex-direction: column;
  width: 75%;
}
.step-texts p {
  font-weight: 400;
} */
.step-item {
border-radius: 15px;
}

#hubspotForm {
  width: 60vw;
  margin-bottom: 10vh;
}
.hubspot-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#hbspt-form-1656602655191-9596552611 {
  margin-bottom: 10vh;
}

#hubspotTY {
  width: 60vw;
  /* background-color: rgb(219, 219, 219); */
}
.hubspotTY-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20vh;
}
.hubspotTY-img {
  margin-top: 5vh;
}
.hubspotTY-outer h6 {
  font-style: italic;
  margin-bottom: 5vh;
}
.BTH {
  border: 1px solid black;
  padding: .5em;
  border-radius: 10px;
}


  /* SPECIALITIES SECTION */
  .specialties-outer {
    background-color: white;
    margin-top: -15vh;
  }
  .specialties-inner {
    display: flex;
    justify-content: center;
    padding-bottom: 10vh;
  }
  .specialty-parent {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    /* width: 80vw; */
    margin-top: 50px;
  }
  .specialty-item {
    width: 275px;
    
  }
  .specialty-img-container {
    overflow: hidden;
  }
  .specialty-img {
    width: 100%;
    height: 215px;
    transition: all .8s ease-in-out;
  }
  .specialty-img:hover {
    transform: scale(1.5); 
  }
  .spec-title {
    margin-bottom: 50vh;
  }
  .specialty {
    line-height: 2;
  }

  /* MULTI CAROUSEL (LOGOS) HOME */
  .logo-carousel {
    background-color: #8f2a2b;
    height: 25vh;
  }
  .logo-carousel-item {
    position: relative;
    top: 15px;
  }
  .logo-carousel-item1 {
    position: relative;
    top: 20px;
  }

  .org-logo {
    padding: 15px;
    height: 200px;
    width: 200px;
    position: relative;
    top: 15px;

  }
  .logo-caption {
    position: relative;
    top: 15px;
    left: 5px;
  }
  .DHA {
    padding-top: 70px;
    height: 135px;
    width: 150px;
  }
  .lds{
    height: 200px;
    width: 150px;
    position: relative;
    top: 15px;
  }
.wsd{
  padding-top: 70px;
  height: 135px;
  width: 250px;
}
.CJC {
  position: relative;
  top: 23%;
}
.carouselHome {
  background-color: #7b0303;
}

/* TESTIMONIAL CAROUSEL HOME */
.testimonial-logo {
  height: 35px;
  width: 35px;
  position: relative;
  right: 10px;
}
.client-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-right: 150px; */
}
.client-container > p {
  line-height: 2.5;
}
.client-container > span {
  font-weight: bolder;
  padding-left: 10px;
}
.paragraph {
  width: 65vw;
  /* padding-left: 35vw; */
}
.paragraph > p {
  line-height: 2.5;
  text-align: center;
  position: relative;
  left: 10px;
  font-weight: bolder;
}
.container > h1 {
  line-height: 3;
}

.testimonial-outer > h1 {
  padding-top: 10vh;
  padding-bottom: 10vh;
}


/* FOOTER */
.footer-outer {
  background-color: #8f2a2b;
  color: #CFCFCF;
  display: flex;
  justify-content: space-around;
  margin-top: 10vh;
  text-align: left;
}
.footer-container {
  display: flex;
  justify-content: space-around;
  background-color: #8f2a2b;
  width: 100vw;
}
.about > p {
  width: 30vw;
  font-size: 14px;
  font-weight: 400;
}
.about > h5 {
  text-align: left;
  line-height: 4;
}
.contact > h5 {
  text-align: left;
  line-height: 4;
}
.about {
  margin-top: 10vh;
  margin-bottom: 10vh;
}
.contact {
  margin-top: 10vh;
  margin-bottom: 10vh;
}


 