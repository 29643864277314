@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch&family=Cinzel&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch&family=Cinzel&family=Kaushan+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yantramanav:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arimo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@200;300;400&display=swap');


.App {
  text-align: center;
  /* font-family: 'Open Sans', sans-serif; */
  -webkit-font-smoothing: subpixel-antialiased;
  /* font-family: 'Fredoka One', cursive; */
  /* font-family: 'Kaushan Script', cursive; */
  /* font-family: 'Sacramento', cursive; */
  /* font-family: 'Montserrat', sans-serif; */
  /* font-family: 'Poppins', sans-serif; */
  /* font-family: 'Libre Franklin', sans-serif; */
  font-family: 'Lato', sans-serif;
  font-weight: 200;
  box-sizing: border-box;
}
.nav-wrapper {
  font-size: 14px;
}
/* ClassName for <a> tags is "a" */
.a {
  text-decoration: none;
  color: #111921;
}
.a2 {
  text-decoration: none;
  color: #cfcfcf;
}
.a2:hover {
  color: #111921;
}

/* p {
  font-family: 'Montserrat', sans-serif;

} */

a.dropdown-item:hover {
  background-color: #cfcfcf;
}
.primary-btn {
  background-color: #7b0303;
  width: 125px;
  color: white;
  font-size: 14pt;
  border: none;
  border-radius: 20px;
  padding: 0.6%;
  -webkit-box-shadow: 4px 7px 16px -7px rgba(0,0,0,0.71); 
  box-shadow: 4px 7px 16px -7px rgba(0,0,0,0.71);
}