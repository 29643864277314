@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Chakra+Petch&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Chakra+Petch&family=Cinzel&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Chakra+Petch&family=Cinzel&family=Kaushan+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Sacramento&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Yantramanav:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arimo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Jost:wght@200;300;400&display=swap);
/* APP.CSS IS ALL THE HOME PAGE STYLES. NOTHING ELSE IS IN THIS STYLESHEET, AND THERE AREN'T HOME PAGE STYLES ELSEWHERE except responsive design*/ 

/* NAV BAR */
nav { 
  box-shadow: 0px 2px 18px 2px rgba(0,0,0,0.03);
}

.navbar-light .navbar-nav .nav-link {
  color: #111921 !important;
  margin-left: 5px;
}
.home-link {
  color: #8f2a2b !important;
}
.nav-link:last-of-type {
  margin-bottom: 3px;
}
.nav-link:hover {
background-color: rgb(241, 240, 240);
}
.dropdown-menu.show {
  border: none;
  -webkit-animation: easeUp 300ms;
          animation: easeUp 300ms;
}
.dropdown-menu {
  font-size: 14px;
  line-height: 12pt;
  position: relative;
  top: 20px;
}

.dropdown:hover>.dropdown-menu { 
  display: block;
  color: rgb(15, 15, 15);
  border: none;
  -webkit-animation: easeDown 300ms ease-in-out forwards;
          animation: easeDown 300ms ease-in-out forwards;
  -webkit-transform-origin: top center;
          transform-origin: top center;
}
@-webkit-keyframes easeDown {
  0% { -webkit-transform: scaleY(0); transform: scaleY(0)}
  80% {-webkit-transform: scaleY(0.9);transform: scaleY(0.9)}
  100% {-webkit-transform: scaleY(1);transform: scaleY(1)}
}
@keyframes easeDown {
  0% { -webkit-transform: scaleY(0); transform: scaleY(0)}
  80% {-webkit-transform: scaleY(0.9);transform: scaleY(0.9)}
  100% {-webkit-transform: scaleY(1);transform: scaleY(1)}
}

/* ul {
  list-style-type: none;
} */

.my-2 {
  margin-top: 0;
}
.nav-wrapper {
  font-size: 12px;
  font-weight: 600;
}
.link { 
  text-decoration: none;
  color: #111921;
}
.link:hover {
  color: #8f2a2b;
  
}
.t-link {
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
  top: 8px;
}
.t-link:hover {
  color: #8f2a2b;
}
.a:hover {
  color: white;
}
.white-hover:hover {
  color: white;
}
.home-link {
  color: #7b0303 !important;
}
.contact-btn {
  border: 1px solid #111921 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding: 5px !important;
  position: relative;
  top: 2px;
  left: 5px;
}
.contact-btn:hover {
  color: #8f2a2b !important;
}

.nav-img {
  height: 48px;
  width: 48px;
}
/* #navbarScroll {
  margin:  40px 0px 40px 750px;
} */
.navbar-wrapper {
position: fixed;
display: flex;
flex-direction: row;
justify-content: space-between;
width: 25vw;
height: 10vh;
background-color: white;
z-index: 1;
}
.navbar-nav-scroll {
  background-color: white;
  max-height: 300px !important;
  position: relative;
  bottom: 20px;
}
.navbar-collapse {
  position: relative;
  top: 21px; 
} 
nav {
  background-color: white;
}
.nav-links {
  position: absolute;
  top: 40px;
  display: flex;
  justify-content: space-around;
  width: 75vw;
  background-color: white;
}

.menu {
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  /* top: 60px;
  right: 0; */
  width: 300px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
  transition: opacity 0.4s ease, visibility 0.4s, -webkit-transform 0.4s ease;
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s, -webkit-transform 0.4s ease;
}

.menu.active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  z-index: 6;
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  
}

.menu li a {
  text-decoration: none;
  color: #333333;
  padding: 15px 20px;
  display: block;
}
.navbar-spacing {
  display: flex;
  justify-content: space-between;
  z-index: 1;   
  height: 10vh;
}
/* .navbar {
  width: 100vw !important;
} */

#navbarScrollingDropdown {
  color: #333333;
}
/* Cookies */
.cookie-btn {
  background-color: #fff;
  color: #000;
  border: 1px solid white;
  border-radius: 3px;
  font-size: 10pt;
  margin-left: 1%;
}
.cookies-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5vh;
}
.cookie-text {
  background-color: rgba(211, 211, 211, 0.267);
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  border-bottom: 5px;
  border-radius: 10px;
  border-style: solid;
  padding: 1rem;
  border-image: linear-gradient(to right, #7b0303, #cfcfcf) 1 50;
  font-weight: 400;
  font-size: larger;
  margin-bottom: 10vh;
}

/* Journey */
.journey-outer {
  background-color: #7b0303;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10vh;
}
.journey-outer h1 {
  margin-top: 2vh;
  margin-bottom: 2vh;
  color: white;
}
.journey-container{
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5vh;
}
.journey-item {
  width: 306px;
  /* background-color: #85ACDE; */
  background-color: white;
  /* color: white; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  margin: 10px 0 10px 0;
  -webkit-filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.25));
}
.journey-item-dark {
  width: 306px;
  /* background-color: #0C366D; */
  background-color: white;
  /* color: white; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  margin: 10px 0 10px 0;
  -webkit-filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.25));
}
.journey-icon {
  font-size: 80px;
  margin: 20px;
}
.journey-icon-dark {
  width: 110px;
}
.journey-texts {
  text-align: left;
  border-left: 1px solid white;
  padding: 20px 0 0 20px;
}
.journey-texts-dark {
  text-align: left;
  border-right: 1px solid white;
  padding: 20px 0px 0 10px;
}
.journey-texts ul {
  font-weight: 400;
}
.journey-texts-dark ul {
  font-weight: 400;
}
/* VIDEO */
.background-video {
  position: relative;
  /* background-color: #111921; */
  height: 75vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
  bottom: 30vh;
}
.center-content {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: black;
  margin-bottom: 30vh;
  margin-left: 5%;
  margin-right: 5%;
}
/* .underline {
  color: #7b0303;
} */
.flyer-comp-outer {
  font-weight: 400;
  font-size: 12pt;
}
.firstrcflyer {
  width: 90%;
}
.flyer-comp-inner {
  width: 100vw;
}
/* .frc-email {
  color: #7b0303 !important;
  font-style: italic !important;
} */

.prev-clip {
  position: absolute;
  margin-top: 70vh;
  /* top: 50%; */
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.logo-wrapper {
  position: relative;
  top: 30vh;
  /* z-index: 1; */
}
/* .prev-logo {
  height: 20vh;
  position: relative;
  
} */

.compH1 {
  position: relative;
  bottom: 30vh;
  margin: 10vh 0 0vh 0;
}
.compH3 {
  
  position: relative;
  bottom: 30vh;
  margin: 3vh 0 10vh 0;
  font-style: italic;
}

/* GROUPS SECTION */
.group-parent {
  background-color: #e9e9e9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 30vh;
  padding-bottom: 10vh;
}
.group-parent > h2 {
  /* padding: 40px; */
  font-size: 24px;
  margin-top: 1%;
}
.group-wrapper {
  width: 80vw;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
}
.group-item {
  width: 400px;
  padding: 10px;
}
.group-item > p{
  font-weight: 400;
}
.group-img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.125);
}

.caption {
  text-align: left;
  line-height: 2;
  font-weight: 400;
}
p {
  text-align: left;
  line-height: 1.8;
}
/* STEP BY STEP */
/* .steps-outer {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  padding: 10% 0;

  position: relative;
  bottom: 30vh;
}
.steps-outer > h1 {
  margin-bottom: 10vh;
}
.steps-inner {
  background-color: #e0e0e0;
  width: 68vw;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 5vh 0 5vh 0;
}
.step-icon {
  font-size: 35pt;
  filter: drop-shadow(10px 5px 4px rgba(0, 0, 0, 0.274));
}
.step-texts {
  display: flex;
  flex-direction: column;
  width: 75%;
}
.step-texts p {
  font-weight: 400;
} */
.step-item {
border-radius: 15px;
}

#hubspotForm {
  width: 60vw;
  margin-bottom: 10vh;
}
.hubspot-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#hbspt-form-1656602655191-9596552611 {
  margin-bottom: 10vh;
}

#hubspotTY {
  width: 60vw;
  /* background-color: rgb(219, 219, 219); */
}
.hubspotTY-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20vh;
}
.hubspotTY-img {
  margin-top: 5vh;
}
.hubspotTY-outer h6 {
  font-style: italic;
  margin-bottom: 5vh;
}
.BTH {
  border: 1px solid black;
  padding: .5em;
  border-radius: 10px;
}


  /* SPECIALITIES SECTION */
  .specialties-outer {
    background-color: white;
    margin-top: -15vh;
  }
  .specialties-inner {
    display: flex;
    justify-content: center;
    padding-bottom: 10vh;
  }
  .specialty-parent {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    /* width: 80vw; */
    margin-top: 50px;
  }
  .specialty-item {
    width: 275px;
    
  }
  .specialty-img-container {
    overflow: hidden;
  }
  .specialty-img {
    width: 100%;
    height: 215px;
    transition: all .8s ease-in-out;
  }
  .specialty-img:hover {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); 
  }
  .spec-title {
    margin-bottom: 50vh;
  }
  .specialty {
    line-height: 2;
  }

  /* MULTI CAROUSEL (LOGOS) HOME */
  .logo-carousel {
    background-color: #8f2a2b;
    height: 25vh;
  }
  .logo-carousel-item {
    position: relative;
    top: 15px;
  }
  .logo-carousel-item1 {
    position: relative;
    top: 20px;
  }

  .org-logo {
    padding: 15px;
    height: 200px;
    width: 200px;
    position: relative;
    top: 15px;

  }
  .logo-caption {
    position: relative;
    top: 15px;
    left: 5px;
  }
  .DHA {
    padding-top: 70px;
    height: 135px;
    width: 150px;
  }
  .lds{
    height: 200px;
    width: 150px;
    position: relative;
    top: 15px;
  }
.wsd{
  padding-top: 70px;
  height: 135px;
  width: 250px;
}
.CJC {
  position: relative;
  top: 23%;
}
.carouselHome {
  background-color: #7b0303;
}

/* TESTIMONIAL CAROUSEL HOME */
.testimonial-logo {
  height: 35px;
  width: 35px;
  position: relative;
  right: 10px;
}
.client-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-right: 150px; */
}
.client-container > p {
  line-height: 2.5;
}
.client-container > span {
  font-weight: bolder;
  padding-left: 10px;
}
.paragraph {
  width: 65vw;
  /* padding-left: 35vw; */
}
.paragraph > p {
  line-height: 2.5;
  text-align: center;
  position: relative;
  left: 10px;
  font-weight: bolder;
}
.container > h1 {
  line-height: 3;
}

.testimonial-outer > h1 {
  padding-top: 10vh;
  padding-bottom: 10vh;
}


/* FOOTER */
.footer-outer {
  background-color: #8f2a2b;
  color: #CFCFCF;
  display: flex;
  justify-content: space-around;
  margin-top: 10vh;
  text-align: left;
}
.footer-container {
  display: flex;
  justify-content: space-around;
  background-color: #8f2a2b;
  width: 100vw;
}
.about > p {
  width: 30vw;
  font-size: 14px;
  font-weight: 400;
}
.about > h5 {
  text-align: left;
  line-height: 4;
}
.contact > h5 {
  text-align: left;
  line-height: 4;
}
.about {
  margin-top: 10vh;
  margin-bottom: 10vh;
}
.contact {
  margin-top: 10vh;
  margin-bottom: 10vh;
}


 
/*-----------------mobile------------------*/
@media screen and (min-width: 320px) {
  .conference-flyer {
    width: 70vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .flyer {
    width: 70vw;
    margin-bottom: 1vh;
  }
  .download {
    width: 50%;
    margin-bottom: 5%;
  }
  .flyer-texts p {
    font-weight: 300;
    margin-bottom: 5%;
  }
  .flyer-texts a {
    color: black;
  }
  .flyer-texts {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 480px) {

  .team-item {
    width: 100%;
  }
  /* .team-inner {
    width: 80vw;
  }  */
  .assessment-training-vid {
    width: 95vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .assessment-training-vid h5 {
    width: 95vw;
  }

  /* HOME */
  .specialty-parent {
    display: flex;
    flex-direction: column;
  }
  .nav-img {
    height: 48px;
    width: 48px;
    margin: 0px 40px 40px 0px;
  }
   .group-parent {
  width: 100vw;
  }
  .group-wrapper {
    width: 100vw;
  }
 .group-item {
    width: 80%;
  }
  .group-img1 {
    height: 157.33px;
  }
  .prev-logo {
    position: relative;
    top: 12vh;
    margin: 0px 8px 0px 5px;
    padding: 0px 20px 0px 10px;
    width: 400git px !important;
    /* height: 100px; */
  }
  .footer-outer {
    flex-direction: column;
  }
  .about {
    margin-left: 20px;
    margin-bottom: 20px;
    margin-top: 0;
  }
  .about > p {
    width: 90vw;
    font-size: 16px;
  }
  .contact {
    margin: 0 0 10px 20px;
  }
  .steps-outer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 99%;
  }
  .steps-inner {
    background-color: #e0e0e0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95vw;
    margin-bottom: 50vh;
    border-radius: 20px;
  }
  .step-item {
    background-color: white;
    width: 85vw;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 2%;
    padding: 1%;
    border-radius: 20px;
  }
  .step-icon {
    font-size: 25pt;
    -webkit-filter: drop-shadow(10px 5px 4px rgba(0, 0, 0, 0.274));
            filter: drop-shadow(10px 5px 4px rgba(0, 0, 0, 0.274));
  }
  .step-texts {
    display: flex;
    flex-direction: column;
    width: 75%;
    font-weight: 300;
  }
  #hubspotTY {
    width: 80vw;
    padding: 2%;
  }
  /* -------CORPORATIONS------ */
  .corp-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  } 
  .corp-paragraph {
    width: 90% !important;
    left: 1vw !important;
  }
  .corp-inner > img {
    width: 80%;
    height: auto;
  }
  .corp-paragraph > h1 {
    font-size: 50px;
    position: relative;
    right: 7%;
  }
  .button-parent-corp {
    width: 85%;
  }
  /*----- CORRECTIONAL ----------*/
  .corr-inner > h1 {
    padding-right: 18px;
  }
  .button-parent-corr {
    margin-top: 40%;
  }
  /*----------- MILITARY--------*/
  .mili-inner > img {
    width: 345px;
    height: 345px;
  }
 .h1 {
    font-size: 40px;
  }
  div.mili-paragraph {
    width: 80vw;
    padding-left: 5px;
  }
  .mili-paragraph > p  {
    font-size: 14px;
  }
  .mili-counters {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 1000px;
  }
  .contact-outer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contact-form {
    width: 250px;
    position: relative;
    right: 12px;
  }
  /*----PUBLIC SAFETY----*/
  .fireFighter-img {
   width: 98%;
   position: relative;
   left: 18px;
  }
  .ps-img-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
  }
  .fireTruck2 {
    display: none;
  }
  .fireTruck3 {
    display: none;
  }
  .ps-pros {
    width: 85%;
    color: black;
    border: none;
    margin-bottom: 30px;
    margin-left: 10%;
    position: relative;
    left: 10% !important;
  }
  .ps-pros h4 {
    position: relative;
    bottom: 40px;
  }
  .PS-List {
    font-size: 16px;
  }
  .PS-list-outer {
    display: flex;
    flex-direction: column;
  }
  .fireFighter-img {
    display: none;
  }
  .ps-carousel-container {
    width: 111px;
    position: relative;
    right: 15px;
  }
  /* -- HUMANITARIAN -- */
  .hum-left-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .hum-para-container {
    position: relative;
  }
  .hum-carousel-img-container {
    height: 700px;
    width: 700px;
    position: relative;
    left: 30px !important;
  }
  /* ---- K12 ----*/
  .k12 > p {
    margin-right: 25px;
  }
  .k12-paragraph {
    margin-right: 15px;
  }
  /*--RESOURCES--*/
  .resources {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .airbb {

  width: 300px;
  display: flex;
  flex-direction: column;
  padding-top: 10%;
  padding-bottom: 10%;
    }
    .airbb2 {
  /* height: 500px; */
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 12%;
  padding-bottom: 10%;
    }
  .airbb-texts {
    width: 80% !important;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding-top: 4vh;
  }
  .resourceImg {
    width: 90%;
    height: 180px;
  }
  .a-z-filter {
    display: flex;
    justify-content: center;
  }
  .card-container {
    width: 80% !important;
  }
  /*------- TEAM ------*/
  .team-modal {
    flex-direction: column;
    width: 85% !important;
    left: 8% !important;
    top: 12% !important;
  }
  .team-modal p {
    font-size: 12px;
  }
  .modal-img-container {
    border-right: 1px solid transparent !important;
    width: 105% !important;
    margin-bottom: 10px;
  }
  .exit-btn {
    display: none;
  }
  .col-md-6 {
    padding-left: 0 !important;
  }
  .overlay {
    background-color: transparent !important;
  }
  .overlay p {
    background-color: transparent;
    height: 40px;
    padding-left: 10px;
    color: white;
  }
  .black-font {
    color: black;
  }
/*------FAITH BASED-------*/
.faith-button-parent {
  width: 85%;
  left: 8% !important;
}
.faith-paragraphs-container {
  width: 100%;
}

}
@media screen and (min-width: 481px) {
/* this is for the flyer */


  /*-----HOME-----*/
  .prev-logo {
    position: relative;
    top: 12vh;
    margin: 0px 8px 0px 10px;
    padding: 0px 20px 0px 10px;
    /* width: 300px !important; */
    /* height: 120px !important; */
  }
  /*------RESOURCES-----*/
   .resources {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .airbb {
  width: 300px;
  display: flex;
  flex-direction: column;
  padding-top: 10%;
  padding-bottom: 10%;
    }
    .airbb2 {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 12%;
  padding-bottom: 10%;
    }
  .airbb-texts {
    width: 80% !important;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding-top: 4vh;
  }
  .resourceImg {
    width: 90%;
    height: 180px;
  }
  .a-z-filter {
    display: flex;
    justify-content: center;
  }
  .card-container {
    width: 80% !important;
  }
  /*------PUBLIC SAFETY------*/
  .PS-list-outer {
    display: flex;
    flex-direction: column;
  }
  .ps-pros {
    width: 85%;
    margin-left: 10%;
  }
  .fireFighter-img {
    display: none;
  }
  .steps-outer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .steps-inner {
    background-color: #e0e0e0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95vw;
    margin-bottom: 20vh;
  }
  .step-item {
    background-color: white;
    max-width: 85vw;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 2%;
    padding: 1%;
  }
  .step-icon {
    font-size: 25pt;
  }
  .step-texts {
    display: flex;
    flex-direction: column;
    width: 75%;
    font-weight: 300;
  }

}

@media screen and (min-width: 760px) {
.team-group {
  display: flex;
  justify-content: center !important;
}
  .team-item p {
    width: 350px;
  }
  /* Journey */
  /* .journey-item-dark {
    position: relative;
    left: 10%
  } */
  .journey-item {
    width: 452px;
    /* height: 245px; */
  } 
  .journey-item-dark {
    width: 452px;
    /* height: 245px; */
  }
  .padright{
   font-size: 15px;
  }
  .journey-icon-dark {
    position: relative;
    right: 10px;
  }

  /*UNI FOOTER*/
  .footer-line-mobile {
    background-color: white !important;
  }
  /*  HOME   */
  .prev-logo {
    position: relative;
    /* top: 8vh; */
    margin: 0px 10px 0px 10px;
    padding: 0px 20px 0px 10px;
  }
  /*------------- PUBLIC SAFETY MIN 760-------------- */
  .ps-img-container {
    display: flex;
    align-items: center;
    justify-self: center;
  }
  .fireTruck2 {
    width: 33.3%;
  }
  .PS-list-outer {
    display: flex;
    flex-direction: row;
  }
  .react-multi-carousel-list {
    width: 100%;
  }
  .fireFighter-img {
    display:inline-block;
    width: 50%;
  }
  .ps-pros {
    height: 600px !important;
    font-size: 16px;
    width: 35%;
    
  }
  .PS-list-outer {
    position: relative;
    margin-left: 15px;
  }
  /*  CORPORATIONS */
  .corp-paragraph {
    margin-left: 10px !important;
  }
  /*---HUMANITARIAN--*/
  .hum-carousel-img-container {
    height: 700px;
    width: 700px;
    position: relative;
    left: 60px !important;
  }
  /*--------RESOURCES-------*/
  .resources {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .airbb {
  /* height: 500px; */
  width: 300px;
  display: flex;
  flex-direction: column;
  padding-top: 10%;
  padding-bottom: 10%;
    }
    .airbb2 {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 12%;
  padding-bottom: 10%;
    }
  .airbb-texts {
    width: 80% !important;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding-top: 4vh;
  }
  .resourceImg {
    width: 90%;
    height: 180px;
  }
  .a-z-filter {
    display: flex;
    justify-content: center;
  }
  .card-container {
    width: 80% !important;
  }

  /* ----- TEAM ----*/
  /* .team-modal {
    flex-direction: column;
    width: 40% !important;
    left: 22% !important;
    top: 25% !important;
  }  */
  .team-modal p {
    font-size: 12px;
  }
  .modal-img-container {
    border-right: 1px solid transparent !important;
    width: 105% !important;
    margin-bottom: 10px;
  }
  .exit-btn {
    display: none;
  }
  .col-md-6 {
    padding-left: 0 !important;
  }

  /*------ TIPS ------*/
  .tips-header2 {
      width: 75vw;
    }  
  .tips-header3 {
      width: 75vw;
    }



}
@media screen and (max-width: 768px) {

  /* .team-item {
    width: 45%;
    position: relative;
    right: 10px;
  } */

}
/*-----------------tablet-------------------*/
@media screen and (max-width: 900px ) {
    /* --------UNI FOOTER--------- */
    .uni-footer {
      flex-direction: column;
    }
    .footer-snippet {
      width: 275px !important;
      justify-content: space-between !important;
    }
    .footer-snippet > p {
      text-align: center;
    }
    p.email-hover-dark {
      text-align: center;
    }
    .footer-snippet-larger > p {
      text-align: center;
    }
    .footer-logo {
      width: 100% !important;
    }
    .footer-line {
      background-color: transparent !important;
    }
    .footer-line-mobile {
      width: 100%;
      height: 1px;
      background-color: #cfcfcf;
    }
  /*----------HOME--------------*/
  .nav-img {
    height: 48px;
    width: 48px;
    margin: 0px 40px 10px 0px;
  }
  .background-video {
    position: relative;
  }
  .prev-logo {
    position: relative;
    /* bottom: 10vh; */
    width: 100vw;
  }
  .group-parent {
    position: relative;
    bottom: 30vh;
  }
.group-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
  .specialty-parent {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 80vw;
  }
  .specialty-img {
    width: 100%;
    height: 215px;
  }
  .carouselHome {
    position: relative;
    bottom: 10vh;
  }
  .paragraph > p {
    font-size: 12px;
  }
  .paragraph {
    width: 80vw;
    position: relative;
    right: 5vw;
  }
  .contact > p {
    font-size: 12px;
  }
  /*--------Faith Based----------*/
  .faith-paragraphs-container {
    width: 75vw;
  }
  
  /*----------CORRECTIONAl-------*/
  
  .corr-para { 
    width: 85%;
    top: 10vh;
  }
  .button-parent-corr {
    width: 85%;

    margin-top: 40%;  
  }
  
  /* CORPORATIONS */
  .corp-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .corp-paragraph > p {
    position: relative;
    font-size: 20px;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  .corp-inner > img {
    width: 80%;
    height: auto;
  }
  .corp-outer > h1 {
    font-size: 50px;
  }
  /* MILITARY */
  .mili-paragraph {
    width: 90vw;
    padding-left: 5px;
  }
  .mili-paragraph > p  {
    font-size: 16px;
  }
  /*PUBLIC SAFETY*/
  .fireTruck2 {
    width: 33.3%;
  }
  .fireTruck3 {
    display: none;
  }
  .ps-pros {
    position: relative;
    /* left: 8vw; */
    top: 3vh;
  }
  .ps-pros h4 {
    position: relative;
    bottom: 40px;
  }
  .ps-pros ul {
    position: relative;
    bottom: 40px;
  }
  /*---- TEAM -----*/
  /* .team-modal {
    flex-direction: column;
    width: 60% !important;
    left: 20% !important;
    top: 12% !important;
  } */
  .team-modal p {
    font-size: 12px !important;
  }
  .modal-img-container {
    border-right: 1px solid transparent !important;
    width: 105% !important;
    margin-bottom: 10px;
  }
  .exit-btn {
    display: none;
  }
  .col-md-6 {
    padding-left: 0 !important;
  }
  /*-------------PPN INFO max 900px--------------------*/
  /* .training-videos-container {
    flex-direction: column;
  } */
  .iframe-video {
    width: 80vw !important;
  }

 
  
  /* .ppn-trainings-outer {
    width: 100%;
  }
  .text-right {
    width: 90vw !important;
  }
  .text-right h1 {
    width: 100vw;
    margin-bottom: 5vh;
  }
  .text-right p {
    width: 80vw !important;
  } */

  .image-right-text-left {
    width: 100%;
  }
  .text-left {
    width: 90vw !important;
    right: 0 !important;
  }
  .text-left h1 {
    width: 100vw;
    margin-bottom: 5vh;
  }
  .text-left p {
    width: 80vw !important;
  }


} 
@media screen and (min-width: 901px) {
  .team-modal {
    width: 60% !important;
    left: 20% !important; 
  }
  /*---------HOME ---------*/
  .specialty-parent {
    width: 80vw;
  }
  .corp-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .corp-paragraph > p {
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 20px;
  }
  .corp-inner > img {
    width: 80%;
    height: auto;
  }
  .corp-outer > h1 {
    font-size: 50px;
  }
  /*------PUBLIC SAFETY----*/
  .ps-pros {
    position: relative;
    left: 8vw;
    top: 3vh;
  }

/*-------RESOURCES------*/
  .airbb {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }
  .airbb-texts {
    width: 50% !important;
  }
  .airbb2 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }
  /*------------PPN INFO MIN 901-------------*/
  .text-left {
    right: 30vw;
  }

  /*-----CORRECTIONAL FACILITIES-----*/
  .button-parent-corr {
    left: 12%;
    margin-top: 10%;
  }
  .corr-para {
    top: 10vh;
    width: 85%;
  }
}
@media screen and (max-width: 991px) {
  .contact-btn {
    border: none !important;
  }
  /*-------PPN INFO MAX 991------*/
  .ppn-trainings-outer {
    width: 100%;
  }
  .text-right {
    width: 90vw !important;
  }
  .text-right h1 {
    width: 100vw;
    margin-bottom: 5vh;
  }
  .text-right p {
    width: 80vw !important;
  }
}

/*-----------------laptop / ipad PRO---------*/
@media screen and (min-width: 1023px) {
  .cookie-text {
    width: 50vw;
  }

  .mhm-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 25vh;
  }
  .mhm-signup {
    width: 75%;
    height: 650px;
    /* margin-bottom: 50px; */
  }
  
  .team-group {
    justify-content: flex-start !important;
  }

  /* this is for the flyer */
.conference-flyer {
  width: 50vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flyer {
  background-position: center;
  background-repeat: no-repeat;
  width: 30vw;
}
.download {
  width: 50%;
  margin-bottom: 5%;
}
.flyer-texts p {
  font-weight: 300;
  margin-bottom: 5%;
}
.flyer-texts a {
  color: black;
}
.flyer-texts {
  display: flex;
  flex-direction: column;
  align-items: center;
}

  /*-- HOME-- */
  .journey-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    width: 50%;
  }
  .prev-logo {
    position: relative;
    /* top: 16vh; */
    width: 75%;
    margin: 0px 10px 0px 10px;
    padding: 0px 20px 0px 10px;
  }
  .specialty-parent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 5vh;
    width: 70vw;
  }
  .specialty {
    color: black;
    /* font-size: 15px; */
  }
  .group-wrapper {
    margin: 50px;
  }
  .group-item {
    margin: 25px;
  }
  .steps-outer {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    padding: 5% 0 0 0;
    /* margin-bottom: 40vh; */
    position: relative;
    bottom: 30vh;
  }
  .steps-outer > h1 {
    margin-bottom: 15vh;
  }
  .steps-inner {
    background-color: #e0e0e0;
    width: 90vw;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding: 5vh 0 5vh 0;
    margin-bottom: 0vh;
  }
  .step-item {
    background-color: white;
    width: 45%;
    /* border: 1px solid black; */
    /* border-radius: 10px; */
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 2%;
    padding: 1%;
  }
  .step-icon {
    font-size: 35pt;
    -webkit-filter: drop-shadow(10px 5px 4px rgba(0, 0, 0, 0.274));
            filter: drop-shadow(10px 5px 4px rgba(0, 0, 0, 0.274));
  }
  .step-texts {
    display: flex;
    flex-direction: column;
    /* width: 75%; */
  }
  .step-texts p {
    font-weight: 400;
  }
  /*-- FAITH BASED--*/
  .icon-container {
    display: flex;
    justify-content: left;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 85%;
    position: relative;
    left: 10vw;
  }
  .faith-paragraphs-container {
    width: 75vw;
  }
  .icon {
    padding: 0px 0px 0px 0px;
    width: 350px;
  }
  /*-- CORRECTIONAL-- */
  /* .button-parent-corr {
    margin-top: 15%;
  }  */


  /* --CORPORATIONS --*/
  .corp-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .corp-paragraph > p {
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 20px;
  }
  .corp-inner > img {
    width: 80%;
    height: auto;
  }
  .corp-outer > h1 {
    font-size: 50px;
  }
    /*--MILITARY-- */
    .mili-paragraph {
      width: 90vw;
      padding-left: 5px;
    }
    .mili-paragraph > p  {
      font-size: 20px;
    }
    /*---------PUBLIC SAFETY--------*/
    .PS-list-outer {
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
    }

  .fireTruck2 {
    width: 33.3%;
  }
  .fireFighter-img {
    width: 33.3%;
  }
  .ps-pros {
    position: relative;
    left: 8vw;
    top: 3vh;
    width: 434px;
  }
  .ps-pros h4 {
    position: relative;
    top: 10px;
  }
  .ps-pros ul {
    position: relative;
    top: 10px;
  }
  
  /*--RESOURCES--*/
  .resources {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin-left: 5%;
  }
  .card-container {
    width: 75vw !important;
  }
  .airbb-texts {
    width: 400px !important;
  }
  .airbb {
    width: 90% !important;
    justify-content: space-around;
  }
  .airbb2 {
    width: 90% !important;
    justify-content: space-around;
  }

    /*------ TIPS ------*/
    .tips-header2 {
      width: 50vw;
    }  
  .tips-header3 {
      width: 50vw;
    }
    .safeUt {
      width: 20% !important;
    }
}

@media screen and (max-width: 1200px) {



  /*-----CORPORATIONS-----*/
  .corp-inner {
    flex-direction: column !important;

  }
  .corp-paragraph {
    /* width: 90vw !important; */
    position: relative;
    /* right: 43%; */
    margin-left: 20px !important;
    margin-right: 10px !important;
  }
  .corp-paragraph > p {
    font-size: 16px !important;
    text-align: left;
  }
  /*------PUBLIC SAFETY----*/
  .fireFighter-img {
    position: relative;
    left: 5%;
  }
  .ps-pros {
    position: relative;
    left: 2%;
  }
  /*-------- TEAM --------*/
  .team-modal {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: white;
    color: black;
    /* height: 350px; */
    width: 60%;
    border: none;
    z-index: 2 !important;
    position: fixed;
    top: 35%;
    left: 20%;
    border-radius: 5px;
    box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.15);
    /* animation: fadeIn 0.5s ease-in-out; */
  }
  /*-----------PPN INFO MAX 1200--------------*/
  .training-videos-container {
    flex-direction: column;
  }
  .ppn-trainings-outer {
    width: 100%;
  }
  .text-right {
    width: 75vw !important;
  }
  .text-right h1 {
    width: 80vw;
    margin-bottom: 5vh;
  }
  .text-right p {
    width: 60vw !important;
  } 
  .training-columns {
    flex-direction: column;
    align-items: stretch;
  } 
  .left-img-wrapper, .right-img-wrapper, .left-img-wrapper2 {
    display: none;
  }
  .steps-inner {
    margin-bottom: 15vh;
  }
}

@media screen and (min-width: 1159px) {

  .team-item {
    width: 50%;
  }
 

  .specialty-parent {
    width: 100vw;
  }

}
/*--------------------DESKTOP-----------------*/
@media screen and (min-width: 1201px) {


/* first responder conf flyer home page */
.center-content {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  color: black;
}
.underline {
  color: #7b0303;
}
.flyer-comp-outer {
  width: 75vw;
  display: flex;
  justify-content: space-evenly;
  font-weight: 400;
  font-size: 12pt;

}
.firstrcflyer {
  width: 40%;

}
.flyer-comp-inner {
  width: 40%;
}
.frc-email {
  color: #7b0303 !important;
  font-style: italic !important;
}

    /* this is for the flyer */
.conference-flyer {
  width: 60vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.flyer {
  background-position: center;
  background-repeat: no-repeat;
  width: 25vw;
}
  /*-----HOME --------*/
  .prev-logo {
    position: relative;
    top: 8vh;
    /* height: 400px !important; */
    margin: 0px 10px 0px 10px;
    padding: 0px 20px 0px 10px;
  }
  /*HUMANITARIAN*/
  .hum-outer {
    display: flex;
  }
  .hum-carousel-img {
    width: 700px;
    height: 700px;
  }
  .hum-right-bottom {
    width: 50vw;
  }
  .hum-carousel {
    position: relative;
    top: 10vh;
  }
  .hum-carousel-img-container {
    height: 700px;
    width: 700px;
    position: relative;
    left: 96px;
  }
  .hum-outer {
    padding-bottom: 10vh;
  }
  /* faith based*/
  .icon-container {
    display: flex;
    justify-content: left;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 85%;

    position: relative;
    left: 10vw;
  }
  .faith-paragraphs-container {
    width: 75vw;

  }
  /* CORRECTIONAL */
  .corr-para {
    width: 55vw;
    font-size: 20px;
  }
  .cor-icon {
    margin-top: 50px;
  }
  /* K 12 */
  .k12 {
    width: 60vw;
    margin-left: 20vw;
  }
  .k12-inner > h1 {
    font-size: 60px;
  }
  /* CORPORATIONS */
  .corp-inner > img {
    width: 30% !important;
    height: auto !important;
  }
  /* MILITARY */
  .mili-paragraph {
    width: 90vw;
    padding-left: 5px;
  }
  .mili-paragraph > p  {
    font-size: 20px;
  }
  .button-parent-mili{
    padding-bottom: 40px;
  }
  /*---------PUBLIC SAFETY----------*/

  .fireTruck2 {
    width: 33.3%;
  }
  .Left-inner {
    width: 100%;
  }
  .fireFighter-img {
    width: 33.3%;
  }
  /*---------RESOURCES-------*/
  /* .hrc-inner {
    margin-left: 15% !important;
  } */
  .resources {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
  }
  /* .card-container {
    width: 90vw !important;
  } */
  .airbb-texts {
    width: 600px !important;
  }
  .airbb {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }
  .airbb2 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }
  .tips-container {
    width: 100vw;
  }
  /*----------------ppn info min 1201---------*/
  .training-columns {
    flex-direction: row;
    align-items: flex-start;
  }

}

@media screen and (min-width: 1375px) {
  .team-group {
    width: 80%;
  }
  .team-item {
    width: 33%;
  }

  .specialty-parent {
    width: 90vw;
  }
  .corp-img {
    margin: 20px;
  }

}
/* ------------------------XL------------------*/
@media screen and (min-width: 1500px) {

/* .mhm-form {
  width: 100%;
  height: 700px;
  margin-top: -100px;
} */


/* this is for the flyer */
.conference-flyer {
  width: 45vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.flyer {
  background-position: center;
  background-repeat: no-repeat;
  width: 18vw;
}

  .steps-inner {
    width: 45vw;
    margin-bottom: 10vh;
  }

  .team-modal {
    width: 40% !important;
    left: 30% !important;
  }
  /* HOME */
  .prev-logo {
    height: 400px;
    margin: 0px 10px 90px 10px;
    padding: 0px 20px 0px 10px;
  }
  .background-video {
    margin-top: 10vh;
  }
  .specialty-parent {
    width: 80vw;
  }
 
  /*Faith Based */
  .icon-container {
    display: flex;
    justify-content: left;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 60%;
    /* padding-left: 10%; */
  }
  /*PROFESSIONAL*/
  .pro-container {
    margin: 8vh 10vw 5vh 10vw;
  }
  .pro-container > h1 {
    font-size: 60px;
    font-weight: 500;
  }
  .pro-para {
    width: 40vw;
  }
  /* CORRECTIONAL */
  /* K 12 */
  .k12 {
    width: 60vw;
    margin-left: 20vw;
  }
  .k12-inner > h1 {
    font-size: 60px;
  }
  /* CORPORATIONS */
 
  .corp-inner > img {
    width: 500px !important;
    height: auto !important;
  }
  .corp-paragraph {
    font-size: 18px;
    width: 90vw;
    display: flex
  }
  .corp-paragraph > p {
    text-align: center;
  }
  .corp-outer > h1 {
    font-size: 60px;
  }
  /* MILITARY */
  .mili-paragraph {
    width: 40vw;
    padding-left: 5px;
  }
  .mili-paragraph > p  {
    font-size: 20px;
  }
  .button-parent-mili{
    padding-bottom: 40px;
  }
  /* --CONTACT-- */
  /*--PUBLIC SAFETY --*/
  .fireFighter-img {
    width: 33.3%;
  }
  /*--RESOURCES--*/
  .resources {
    padding: 0 5vw 0 5vw;
  }
  .resources {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
  }
  /* .card-container {
    width: 90vw !important;
  } */
  .airbb-texts {
    width: 600px !important;
  }
  .airbb {
    width: 75% !important;
    justify-content: space-around;
  }
  .airbb2 {
    width: 75% !important;
    justify-content: space-around;
  }

/*------ TIPS ------*/
  .tips-header2 {
    width: 35vw;
  }  
 .tips-header3 {
    width: 35vw;
  }
}
@media screen and (min-width: 1719px) {
  .specialty-parent {
    width: 73vw !important;
  }
  /* .team-item {
    width: 20vw !important;
  } */
}
@media screen and (min-width: 1900px) {
    /* HOME */
    .prev-logo {
      position: relative;
      /* top: 3vh; */
      /* width: 70%; */
      height: 400px !important;
      margin: -60px 10px 90px 10px;
      padding: 0px 20px 0px 10px;
    }
    .specialty-parent {
      width: 60vw !important;
    }
    /*PUBLIC SAFETY*/
    .PS-list-outer {
      width: 75vw;
      position: relative;
      /* left: 4vw; */
      }
      .fireFighter-img {
        width: 33.3%;
      }
      /*----RESOURCES-----*/
      .resources {
        padding: 0 0vw 0 18vw;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
      }
      /* .card-container {
        width: 95vw !important;
      } */
      .line {
        width: 65% !important;
      }
      .airbb-texts {
        width: 600px !important;
      }
      .airbb {
        width: 65% !important;
        justify-content: space-around;
      }
      .airbb2 {
        width: 65% !important;
        justify-content: space-around;
      }
}
/* ------------------ SUPER LARGE ------------ */
@media screen and (min-width: 2000px) {
  /*----HOME--------*/
  .journey-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    width: 45%;
  }
  .prev-logo {
    position: relative;
    top: 3vh;
    width: 50%;
    height: 250px;
    margin: 0px 10px 0px 10px;
    padding: 0px 20px 0px 10px;
  }
  .specialty-parent {
    width: 60vw !important;
  }
   /*------------ PUBLIC SAFETY----------- */
   .PS-Outer {
    display: flex;
    /* justify-content: center;
    align-items: flex-start; */
  }
  .PS-Left {
    width: 50vw;
  }

  .PS-Right {
    width: 50vw;
    height: auto;
    /* overflow: hidden; */
  }
  .fireFighter-img {
    height: auto;
    width: 50vw;
  }
  .PS-Left > h1{
    font-size: 50px;
  }
  .PS-Left > p {
    font-size: 18px;
  }
  .PS-List > li {
    font-size: 18px;
  }
  .button-parent {
    padding-top: 100px;
  }
    /*PUBLIC SAFETY*/
    .fireTruck2 {
      width: 33.3%;
    }
    .fireFighter-img {
      width: 33.3%;
    }
/*---------CORPORATIONS------*/
.corp-paragraph {
  width: 90vw;
}

    /*------ TIPS ------*/
    .tips-header2 {
      width: 35vw;
    }  
  .tips-header3 {
      width: 35vw;
    }
}

.mhm-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 25vh;
}
.mhm-signup {
  width: 75%;
  height: 650px;
  /* margin-bottom: 50px; */
}

.blog-link {
  color: black;
}

/*---------scroll to top btn----------------*/
.to-top-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 25px;
  background-color: rgba(27, 27, 27, 0.849);
  color: white;
  /* background-color: white;
  color: black; */
  cursor: pointer;
  border: none;
  border-radius: 50%;
  z-index: 2;
}
.to-top-btn:hover {
  background-color: black;
}

/*-----------------------DISCLOSURES--------------------*/
/* .disclosures-container {

} */

.disclosures-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.disclosures-texts {

  text-align: left;
  /* background-color:; */
  max-width: 80vw;
}

/*--------------UNIversal FOOTER-------------*/
.uni-footer {
  background-color: white !important;
  padding: 2vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-logo {
 width: 20%;
 padding-right: 2%;
}
.footer-snippet {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 225px;
  font-size: 14px;
  font-weight: bold;
}
.footer-snippet-larger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 275px;
  font-size: 14px;
  font-weight: bold;
}
.footer-snippet > p:first-of-type, .footer-snippet-larger > p:first-of-type{
  position: relative;
  top: 6px;
  margin-right: 2%;
}
.email-hover-dark:hover {
  color: #8f2a2b;
}
.footer-icons {
  color: white;
  display: flex; 
  justify-content: center;
  width: 35px;
  height: 35px;
  /* border: 1px solid #8f2a2b; */
  background-color: #8f2a2b;
  border-radius: 50%;
}
.footer-icon {
  position: relative;
  top: 9px;
  font-size: 15px;
}
/* .socials-footer {
  width: 5%;
  font-size: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  bottom: 2px;
}
.social-f {
  color: #cfcfcf;
} */
.footer-line {
  height: 60px;
  width: 1px;
  background-color: #cfcfcf;
}
.whitespace {
  height: 10vh;
}
/* ---------------------TEAM---------------- */ 

.under-construction {
  width: 40%;
}
.teams-outer {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}
.teams-outer > h1 {
  margin-top: 5vh;
  margin-bottom: 5vh;
}
.teams-outer > h2 {
  margin-bottom: 3vh;
}
.team-inner h2 {
  position: relative;
  left: 6%;
}
.team-inner {
  width: 75vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.team-group {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.team-item {
  /* width: 33%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.team-item p {
  /* width: 350px; */
  font-weight: 400;
  font-size: 12pt;
}
.team-background {
  /* background-color: #8f2a2b; */
  color: #CFCFCF;
}
.team-img {
  height: 250px;
  width: 250px;
  margin: 15px;
  margin-right: 20px;
  border-radius: 50%;
}


.thumb__title {
  font-size: 25px;
  font-weight: 700;
  line-height: 1.33;
  text-align: center;
}
.thumb__subtitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.71429;
  font-style: italic;
  text-align: center;
}
.thumb-corporate__caption {
  margin-top: 10px;
}
.team-spacing {
  padding-top: 8vh;
  padding-bottom: 5vh;
}
.team-inner {
  padding-bottom: 3vh;
}
.team-inner > h2 {
  font-size: 40px;
}

/* .overlay {
  position: absolute;
  bottom: 16px;
  left: 20px;
  right: 0;
  background-color: #f7f7f7b7;
  overflow: hidden;
  width: 99%;
  height: 40px;
  transition: .5s ease;
  color: #111921;
}
.overlay > p {
  position: relative;
  left: 10px;
  font-weight: 300;
} */
.team-member-container {
  overflow: hidden;
}

.team-member-container:hover .overlay {
  height: 25%;
  overflow-x: hidden;
}
.open-button {
  background: none;
  border: none;
}
.exit-btn {
  background-color: white;
  border: none;
  height: 20px;
  width: 20px;
}

  .team-modal {
    -webkit-animation: scaleUp .5s ease forwards;
            animation: scaleUp .5s ease forwards;
  }
   /* .team-flex {
    animation: scaleBack .5s ease-in-out forwards;
  } */
  .inactive {
    -webkit-animation: quickScaleDown 0s .1s linear forwards;
            animation: quickScaleDown 0s .1s linear forwards;
  }
  /* .dark-background {
    animation: fadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
  } */
  .inactive {
    -webkit-animation: fadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
            animation: fadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }
  .inactive {
      -webkit-animation: scaleDown .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
              animation: scaleDown .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
  }
  /* .team-flex {
    animation: scaleForward .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
  } */

@-webkit-keyframes fadeIn {
  0% {
    background: rgba(255, 255, 255, 0.466);
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    background: rgba(255, 255, 255, 0.466);
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    background:rgba(0,0,0,.0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    background:rgba(0,0,0,.0);
  }
}

@-webkit-keyframes scaleUp {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity:0;
  }
  100% {
    -webkit-transform:scale(1) translateY(0px);
            transform:scale(1) translateY(0px);
    opacity:1;
  }
}

@keyframes scaleUp {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity:0;
  }
  100% {
    -webkit-transform:scale(1) translateY(0px);
            transform:scale(1) translateY(0px);
    opacity:1;
  }
}

@-webkit-keyframes scaleDown {
  0% {
    -webkit-transform:scale(1) translateY(0px);
            transform:scale(1) translateY(0px);
    opacity:1;
  }
  100% {
    -webkit-transform:scale(.8) translateY(1000px);
            transform:scale(.8) translateY(1000px);
    opacity:0;
  }
}

@keyframes scaleDown {
  0% {
    -webkit-transform:scale(1) translateY(0px);
            transform:scale(1) translateY(0px);
    opacity:1;
  }
  100% {
    -webkit-transform:scale(.8) translateY(1000px);
            transform:scale(.8) translateY(1000px);
    opacity:0;
  }
}

@-webkit-keyframes scaleBack {
  0% {
    -webkit-transform:scale(1);
            transform:scale(1);
  }
  100% {
    -webkit-transform:scale(.85);
            transform:scale(.85);
  }
}

@keyframes scaleBack {
  0% {
    -webkit-transform:scale(1);
            transform:scale(1);
  }
  100% {
    -webkit-transform:scale(.85);
            transform:scale(.85);
  }
}

@-webkit-keyframes scaleForward {
  0% {
    -webkit-transform:scale(.85);
            transform:scale(.85);
  }
  100% {
    -webkit-transform:scale(1);
            transform:scale(1);
  }
}

@keyframes scaleForward {
  0% {
    -webkit-transform:scale(.85);
            transform:scale(.85);
  }
  100% {
    -webkit-transform:scale(1);
            transform:scale(1);
  }
}

@-webkit-keyframes quickScaleDown {
  0% {
    -webkit-transform:scale(1);
            transform:scale(1);
  }
  99.9% {
    -webkit-transform:scale(1);
            transform:scale(1);
  }
  100% {
    -webkit-transform:scale(0);
            transform:scale(0);
  }
}

@keyframes quickScaleDown {
  0% {
    -webkit-transform:scale(1);
            transform:scale(1);
  }
  99.9% {
    -webkit-transform:scale(1);
            transform:scale(1);
  }
  100% {
    -webkit-transform:scale(0);
            transform:scale(0);
  }
}
.team-modal {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: white;
  color: #111921;
  /* height: 350px; */
  width: 60%;
  border: none;
  z-index: 2 !important;
  position: fixed;
  /* bottom: 30%;
  left: 28%; */
  top: 22%;
  left: 18%;
  border-radius: 5px;
  box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.15);
  /* animation: fadeIn 0.5s ease-in-out; */
}
.team-modal p {
  font-size: 16px;
  font-weight: 400;
}
.dark-background {
  background: #00000080;
  z-index: 1 !important;
  width: 100%;
  height: 300vh;
  position: fixed;
  bottom: 0;
}
.hide {
  display: none;
}

.modal-img-container {
  border-right: 1px solid #111921 !important;
  margin-right: 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 250px;
}
.modal-img {
  border-radius: 50%;
  width: 235px;
  position: relative;
  right: 2%;
}
.modal-text {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2%;
}
.modal-text > h4 {
  font-style: italic;
  padding-bottom: 8%;
  font-family: 'Montserrat', sans-serif;
}
/*--------------------- PUBLIC SAFETY--------------------- */ 
.blurb-container {
  border: 2px solid orange;
  width: 35%;
  height: 400px;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  flex-wrap: wrap;
}
.blurb {
  width: 30%;
  border: 1px solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.liaison {
  background-color: transparent;
  display: inline-block;
  width: auto;
  max-width: 100%;
  border: 1px solid #e0e0e3;
  color: #CFCFCF;
  border-radius: 3px;
  padding: 12px 30px;
  font-size: 12px;
  line-height: 24px;
  font-family: "Libre Franklin", ;
  font-weight: 600;
  letter-spacing: .06em;
  transition: .33s all ease;
  white-space: normal;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
}
.liaison:hover {
  background-color: whitesmoke;
  color: #111921;
}
.button-parent {
  display: flex;
  justify-content: center;
  margin-bottom: 8vh;
  margin-top: 8vh;
}
.PS-Outer {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.PS-Left {
  background-color: #8f2a2b;
  color: #e4e4e4;
  width: 100%;
}
.PS-Left p {
  font-weight: 300;
}
.Left-inner > h1 {
  margin-top: 50px;
  font-size: 50px;
  font-weight: 300;
}
.Left-inner {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ps-pros {
  border-left: 4px solid #e4e4e4;
  color: #111921;
  color: #e4e4e4;
  z-index: 1;
  padding: 5vh .5vw 0vh 2.9vw;
}
.ps-para {
  width: 60vw;
  position: relative;
  /* left: 20vw; */
  font-size: 18px;
}
.ps-para > p {
  text-align: center;
}
.PS-list-outer {
  /* width: 75vw;
  position: relative;
  left: 12vw; */
  top: 50px;
  padding-bottom: 100px;
  display: flex;
  justify-content: space-around;
  position: relative;
  right: 10vw;
}
.PS-list-outer h4 {
  text-align: left;
  font-size: 30px;
  position: relative;
  /* top: 10px; */
}
.PS-List {
  text-align: left;
  font-weight: bolder;
  font-size: 20px;
  line-height: 35pt;
  position: relative;
  /* top: 10px; */
}
.fireFighter-img {
  width: auto;
  height: auto;
  
  /* box-shadow: 10px 10px 32px 0px rgba(0,0,0,0.5);
  -webkit-box-shadow: 10px 10px 32px 0px rgba(0,0,0,0.5);
  -moz-box-shadow: 10px 10px 32px 0px rgba(0,0,0,0.5); */
}
.fireFighter-img1 {
  width: 33.3%;
  height: auto;

  box-shadow: 10px 10px 32px 0px rgba(0,0,0,0.5);
-webkit-box-shadow: 10px 10px 32px 0px rgba(0,0,0,0.5);
-moz-box-shadow: 10px 10px 32px 0px rgba(0,0,0,0.5);
}
.fireTruck2 {
  width: 33.3%;
  height: auto;
  box-shadow: 10px 10px 32px 0px rgba(0,0,0,0.5);
-webkit-box-shadow: 10px 10px 32px 0px rgba(0,0,0,0.5);
-moz-box-shadow: 10px 10px 32px 0px rgba(0,0,0,0.5);
}

.ps-img-container {
  background-color: #8f2a2b;
  /* display: grid; */
}
.icon-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 215px;
  height: 215px;
  border: 2px solid #e4e4e4;
  border-radius: 50%;
}
.icon-item > p {
  text-align: center;
  /* color: #8f2a2b; */
}
.react-multi-carousel-item {
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
}
.icon {
  font-size: 30px;
}
.icon-light {
  color: #e4e4e4;
  font-size: 30px;
  text-align: center;
}
.ps-icon-para {
  font-size: 16px;
  text-align: center;
  width: 70%;
  /* color: #8f2a2b; */

}
.ps-icon-para > p {
  text-align: center;
  /* color: #8f2a2b; */

}
.ps-icon-para-rapid {
  font-size: 15px;
  text-align: center;
  width: 80%;
  /* color: #8f2a2b; */
}
.ps-icon-para-rapid > p {
  text-align: center;
}
/* .PS-Carousel {
  width: 80%;
  position: relative;
  margin-left: 10px;
} */
.ps-carousel-container {
  width: 100%;
  position: relative;
  left: 0px;
}


/* -------------------HUMANITARIAN------------------------ */
.hum-outer {
  background-color: #8f2a2b;
  color: #CFCFCF;
  text-align: center;
  padding-top: 30px;
  padding-left: 10vw;
}
.hum-para-container > p {
  text-align: center;
}
.hum-carousel-img {
  width: 75%;
  height: auto;
}
.hum-left-top {
  padding: 20px;
  position: relative;
  right: 5vw;
}
.hum-left-top > h1 {
  font-size: 60px;
  font-weight: 300;
}
.achf-img-container {
  padding: 10px;
  margin-bottom: 20px;
}
.ACHF {
  width: 40%;
}
.here {
  font-weight: 800;
  text-decoration: none;
  color: #CFCFCF;
}
.here:hover {
  font-size: 15px;
  color: #cfcfcf;
}
/* needed for the humanitarian image carousel, but it does effect all the other carousels. */
/* .react-multi-carousel-track {
  left: 80px
} */


/* ---------------------FAITH BASED------------------ */
/* .faith-inner {
  background-color: #8f2a2b;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
} */

.faith-inner {
  background-image: url(/static/media/faithBased.8c062426.jpg);
  /* height: 100vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; */
  position: relative;
  isolation: isolate;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.faith-inner::before {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  background: linear-gradient(0deg, rgba(230, 230, 230, 0.719), rgba(230, 230, 230, 0.37) );
}

.faith {
  padding: 5vh;
  font-size: 50px;
  font-weight: 400;
  /* color: #b1b1b1; */
}
.faith-paragraphs-container{
  width: 55%;
  font-weight: bolder;
  border-left: 4px solid #8f2a2b;
  text-align: left;
  position: relative;
  left: 10%;
  top: 10vh;
}
.faith-paragraphs-container p {
  font-size: 18px;
  padding: 2vh;
  text-align: left;
  margin-left: 2%;
}
/*---------------ICONS---------------*/
.icon-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 85vw;
  flex-wrap: wrap;
  position: relative;
  right: 8vw;
}
.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  padding: 20px;
  width: 200px;
}
.icon > p {
  font-size: 20px;
  text-align: center;
}

.icon-circle {
  font-size: 30px;
  border: 1px solid #111921;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-circle:hover {
  background-color: white;
  border: 2px solid white;
}
.faith-button-parent {
  margin-bottom: 10vh;
  margin-top: 15vh;
  display: flex;
  justify-content: flex-start;
  position: relative;
  left: 12%;
}
.faith-liaison {
  background-color: whitesmoke;
  display: inline-block;
  width: auto;
  max-width: 100%;
  border: 1px solid #e0e0e3;
  color: #CFCFCF;
  border-radius: 3px;
  padding: 12px 30px;
  font-size: 12px;
  line-height: 24px;
  font-family: "Libre Franklin", ;
  font-weight: 600;
  letter-spacing: .06em;
  transition: .33s all ease;
  white-space: normal;
  text-transform: uppercase;
  text-align: center;
}
.faith-liaison:hover {
  background-color: transparent;
  color: #111921; 
}


/*-----------------PROFESSIONAL-------------------*/
.pro-inner {
  padding: 20px;
  text-align: left;
  background-image: url(/static/media/plantFlipped.5487dfd8.jpg);
  height: 100vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  isolation: isolate;
  overflow: hidden;
}
.pro-inner::before {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  background: linear-gradient(0deg, rgba(230, 230, 230, 0.822), rgba(230, 230, 230, 0.521) );
}


.pro-container > h1 {
  padding-top: 40px;
  padding-left: 2%;
  padding-bottom: 20px;
  border-left: 4px solid #8f2a2b;
  margin-bottom: 0%;
}
.pro-para > p {
  margin-top: 0%;
  padding-top: 30px;
  padding-bottom: 80px;
  padding-left: 4%;
  font-weight: 600;
  border-left: 4px solid #8f2a2b;
}
.professional-contact {
  padding-left: 4%;
}
.liaison-dark {
  background-color: transparent;
  display: inline-block;
  width: auto;
  max-width: 100%;
  border: 1px solid rgb(22, 22, 22);
  color: rgb(22, 22, 22);
  border-radius: 3px;
  padding: 15px 35px;
  font-size: 16px;
  line-height: 24px;
  font-family: "Libre Franklin", ;
  font-weight: 800;
  letter-spacing: .06em;
  transition: .33s all ease;
  white-space: normal;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
}
.liaison-dark:hover {
  background-color: rgba(245, 245, 245, 0.582);
}
/*----------------------CORRECTIONAL FACILITIES-------------------*/
.corr-inner {
  background-image: url(/static/media/lockAndKey.b158afa9.jpg);
  background-size: cover;
  background-position: unset;
  object-fit: cover;
  position: relative;
  isolation: isolate;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.corr-inner::before {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  background: linear-gradient(0deg, rgba(230, 230, 230, 0.932), rgba(230, 230, 230, 0.801) );
}
.correctional {
  padding: 20px;
  font-weight: 300;
  font-size: 50px;
}
.corr-para {
  border-left: 4px solid #8f2a2b;
  text-align: left;
  position: relative;
  left: 10%;
}
.corr-para > p {
  font-size: 20px;
  padding: 20px;
  font-weight: 500;
}
.button-parent-corr {
  /* margin-top: 15%; */
  margin-bottom: 100px;
  position: relative;
  /* left: 12%; */
}
.cor-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  padding-top: 20px;
  /* padding-left: 20px; */
  /* padding-right: 40px; */
  width: 250px;
}
.carousel-container-cor {
  width: 100vw;
  position: relative;
}

.cor-icon > p {
  font-size: 20px;
  text-align: center;
}
.icon-circle-cor {
  font-size: 30px;
  border: 1px solid #111921;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-circle-cor:hover {
  background-color: #111921;
  color: white;
}
/* K12 */
.k12-inner {
  background-color: #8f2a2b;
  /* text-align: center !important; */
  color: #b1b1b1;
}
.k12-inner > h1 {
  padding: 25px;
  font-size: 40px;
  font-weight: 300;
}
.k12-inner > p {
  /* text-align: center !important; */
  font-size: 20px;
  /* font-weight: 500; */
  padding-left: 25px;
  padding-right: 2px;
}
.k12 {
  /* text-align: center !important; */
  font-size: 20px;
  /* font-weight: 500; */
  padding-left: 25px;
  padding-right: 2px;
}
.k12 > p {
  text-align: center;
  font-weight: bolder;
}
.k12-img {
  max-width: 375px;
  padding: 30px;
}
.k12-liaison {
  padding: 40px;
}
.k12-paragraph {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75vw;
  /* margin-right: 150px; */
}

.k12-paragraph > p  {
  text-align: center;
  font-weight: bolderd;
}
/* ---------------------CORPORATIONS-------------------- */
/* .corp-outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
} */
.corp-inner {
  background-image: url(/static/media/officeSetting.00c7a3d6.jpg);
  background-size: cover;
  background-position: unset;
  object-fit: cover;
  position: relative;
  isolation: isolate;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.corp-inner::before {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  background: linear-gradient(0deg, rgba(230, 230, 230, 0.678), rgba(230, 230, 230, 0.801) );
}
.corp-inner > img {
  width: 80%;
  height: auto;
}
.corp-paragraph > h1 {
  margin-top: 5vh;
  font-size: 50px;
  font-weight: 300;
}
.corp-paragraph {
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: left;
  border-left: 4px solid #8f2a2b;
  margin-top: 10vh;
  padding: 0 20px 0 35px;
  position: relative;
  left: 5vw;
}
.corp-paragraph > p {
  text-align: center;
  font-size: 20px;
  text-align: left;
  font-weight: bolder;
}
.button-parent-corp {
  /* width: 35%; */
  display: flex;
  justify-content: center;
  margin-top: 8vh;
  margin-bottom: 10vh;
  position: relative;
  left: 7%;
}
.corp-inner > img:hover {
  box-shadow: 0px 20px 20px -10px rgba(255, 255, 255, 0.65),
              0px -20px 20px -10px rgba(255, 255, 255, 0.65);
}
.box-shadow-slow {
  transition: 1s ease;
}
/* --------------MILITARY--------------- */

.parallax-background {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.mili-inner {
  background-image: url(/static/media/fadedflag.1db86b83.jpg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
.mili-inner > h1 {
  padding: 40px 0px 40px 0px;
  font-size: 50px;
  font-weight: 300;
}
.mili-img {
  width: 406px;
  height: 406px;
  border-radius: 50%;
  margin-bottom: 50px;
}
.mili-paragraph {
  text-align: center;
  font-weight: 300;
  font-size: 18px
}
.mili-paragraph > p {
  text-align: center;
}
.button-parent-mili {
  padding: 40px 0px 100px 0px;
}
.liaison-mili {
  background-color: transparent;
  display: inline-block;
  width: auto;
  max-width: 100%;
  border: 1px solid rgb(31, 31, 31);
  color: rgb(31, 31, 31);
  border-radius: 3px;
  padding: 12px 30px;
  font-size: 12px;
  line-height: 24px;
  font-family: "Libre Franklin", ;
  font-weight: 600;
  letter-spacing: .06em;
  transition: .33s all ease;
  white-space: normal;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
}
.liaison-mili:hover{
  background-color: whitesmoke;
  color: #111921;
}
.mili-counters {
  display: flex;
  justify-content: space-evenly;
  width: 80vw;
}
.counter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  width: 200px;
  padding-bottom: 10px;
}
.counter > h2 {
  font-size: 35px;
}
.counter-icon {
  font-size: 40px;
  color: #8f2a2b
}
.CountUp{
  font-size: 40px;
  font-weight: 300;
}

/* --------------CONTACT--------------- */
.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: left;
  height: 75vh;
  margin-top: 50px;
  margin-bottom: 70px;
}
.contact-form > h1 {
  text-align: left;
}
.contact-details {
  margin-top: 45px;
  margin-bottom: 140px;
}
.contact-details > h5 {
  text-align: left;
}
.contact-details > h1 {
  text-align: left;
}
.socials {
  width: 30%;
  font-size: 32px;
  display: flex;
  justify-content: space-between;
}
.social {
  color: #8f2a2b;
}
.social:hover {
  color: #7b0303a4;
}
.contact-inner {
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  isolation: isolate;
  overflow: hidden;
}
.background {
  background-image: url(/static/media/handsin.1a0174c6.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  isolation: isolate;
  overflow: hidden;
}
.background::before {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.726), rgba(240, 240, 240, 0.418) );
}

.contact-input {
  width: 350px;
  min-height: 50px;
  border-radius: 5px;
  border: none;
  text-align:center;
}
.contact-input:focus {
  outline: 1px solid #8f2a2b;
}
.input-icons {
  position: absolute;
  margin-top: 12px;
  margin-right: 5px;
  color: #7b0303a4;
  min-width: 50px;
  font-size: 25px;
  text-align: center;
}
.form-btn {
  width: 150px;
  height: 50px;
  border-radius: 5px;
  border: none;
  background-color: #8f2a2b;
  color: #CFCFCF;  
}
.form-btn:hover {
  background-color: #CFCFCF;
  color: #8f2a2b;
}
/*-------------TRAININGS--------*/
.trainings-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
iframe {
  margin-bottom: 100px;
  width: 100vw;
}
.trainings-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.trainings-inner h1 {
  margin-top: 10vh;
}
.courses-container {

  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.training-soon {
  width: 50%;
  margin-top: 10%;
}

/*------------- RESOURCES -----------*/
.hotline-resource-container {
  background-color: white;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
}
.hrc-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 5%;
  font-size: 14pt;
  font-weight: 400;
}

.resource-outer {
  margin-top: 5vh;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
}
#h3 {
  margin-top: 10vh;
  font-size: 50px;
}
.resource-input {
  margin-top: 7vh;
  width: 40vw;
}
.card-container {
  margin-top: 8vh;
  width: 65%;
}

.link2 {
  text-decoration: none;
  color: #CFCFCF;
}
.link2:hover {
  color: #111921;
}
.resources {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  flex-wrap: wrap;
  margin-bottom: 100px;
}
.rsc-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.line {
  background-color: rgb(218, 218, 218);
  height: 1px;
  width: 100%;
  margin-top: 15px;
}

.airbb {
 display: flex;
 justify-content: space-between;
 align-items: center;
 padding: 0;
}
.airbb2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
 }
.resourceImg {
  width: 275px;
  height: 180px;
  border-radius: 15px;
  box-shadow: 10px 10px 17px 0px rgba(0,0,0,0.18);
  -webkit-box-shadow: 10px 10px 17px 0px rgba(0,0,0,0.18);
  -moz-box-shadow: 10px 10px 17px 0px rgba(0,0,0,0.18);
}
.airbb-texts {
  width: 600px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  font-weight: bolder;
  /* padding-top: 4vh; */
}
.airbb-texts > h4 {
  font-weight: 900;
  color: #8f2a2b;
}
.airbb-texts p {
  font-size: 12px;
}
.airbb-texts > p:first-of-type {
  font-size: 14px;
}
.airbb-btn {
  border: none;
  border-radius: 50px;
  background-color: #8f2a2b;
  color: rgb(255, 255, 255);
}


/*----------- TIPS --------*/

ol {
  text-align: left;
  line-height: 25pt;
}
ul {
  text-align: left;
  line-height: 25pt;
}
.LI {
  font-weight: bolder;
}
.tips-container {
  margin-top: 10vh;
}
.tips-container h1 {
  margin-top: 2vh;
  font-size: 40px;
  font-weight: bolder;
  text-align: center;
}
.CIT {

  font-weight: 400;
}
.CIT h5:first-of-type {
margin: 5vh 10vw 5vh 0;
} 
.CIT h5 {
  font-weight: bolder;
}
.CIT p {
  margin-left: 5vw;
  line-height: 15pt;
}
.CRP {
  margin-top: 12vh;
  text-align: left;
  font-weight: 400;

}
.CRP h2 {
  margin-left: 5vw;
}
.phones {
  text-align: left;
  margin:  12vh 2vw 10vh 5vw;
}
.li-phone {
  font-weight: 300;
}
.red-dec-border {
  /* background: #F2F2F2; */
  border-top: 2px solid #8f2a2b;
  border-bottom: 2px solid #8f2a2b;
  padding: 2em 0 0.5em;
  position: relative;
  margin-bottom: 10vh;
}
.in-need {
  margin-top: 5vh;
  border: 1px solid black;
}
/* .red-dec-border::after {
  background: white;
  border: 2px solid #8f2a2b;
  border-radius: 50%;
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  left: 50%;
  margin-left: -12px;
  position: absolute;
  top: -14px;
  transform: rotate(45deg);
} */
.tips-header {
  background: url(/static/media/ogdenPD.97fc70a1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.tips-header2 {
  background: url(/static/media/ogdenArch.7a61a5e8.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 10vh;
  padding-top: 5vh;
}

.tips-header3 {
  background: url(/static/media/officeHands2.14debd22.jpg);
  /* background-color: #F6E9E8; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 10vh;
  margin-bottom: 10vh;
  padding-top: 5vh;
}
.tips-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.red-link {
  text-decoration: none;
  font-style: italic;
  color: #8f2a2b
}
.safeUt {
  width: 40%;
}
.clearfield-logo-tips {
  max-width: 35%;
}
.rtt {
  width: 90%;
}
.reactions-to-trauma {
  border: 1px solid black;
  max-width: 99vw;
}
.reactions-to-trauma li {
  list-style-type: disc;
}
table,
th,
td {
    border: 1px solid black !important;
    text-align: left;
  
}
table {
  max-width: 93vw;
  margin-left: 3%;
  margin-bottom: 5vh;
}
th {
  font-size: 10px;
}
td {
  font-size: 12px;
  padding: 3px;
}

/*-----SURVEYS-------*/

.survey {
  text-align: left;
  width: 90vw !important;
}

#surveyResult { 
  width: 60%;
  margin-left: 5vw;
}

/*--------- PPN WELCOME PAGE -----------*/
.conf-mail {
  display: block;
  position: relative;
  border: none;
  padding: 4px;
  border-radius: 5px;
  font-weight: 300;
  color: white;
  background-color: #7b0303;
}
.conf-mail::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: rgba(255,255,255,0.4);
 transition: none;
}
.conf-mail:hover::after {
  width: 120%;
  background-color: rgba(255,255,255,0);
  transition: all 0.4s ease-in-out;
}
/* this is for the flyer

.conference-flyer {
  width: 35vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flyer {
  background-position: center;
  background-repeat: no-repeat;
  width: 15vw;
}
.download {
  width: 50%;
  margin-bottom: 5%;
}
.flyer-texts p {
  font-weight: 300;
  margin-bottom: 5%;
}
.flyer-texts a {
  color: black;
}
.flyer-texts {
  display: flex;
  flex-direction: column;
  align-items: center;
} */
.providers {
  background-color: orange;
}
/*contains the whole page minus the nav bar*/
.ppn-wrapper {
  /* height: 1600px; */
  /* background-color: #f1f1f1; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
/*contains the two divs that make up the header*/
.ppn-header-wrapper {
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0;
}
/* below is the translucent overlay in the header*/
.ppn-header {
  /* background-color: #b1b1b1c2; */
  /* background-color: #8f2a2ab2; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  /* this height is going to be not good being a hard coded px. make it so it's always the same height as the image next to it.*/
  /* height: 458px; */
  z-index: 1;
}
/*the image contained in the header*/
.header-img {
  width: 40%;
  position: relative;
  /* right: 5%; */
}
/*first div of image and text - welcome paragraph*/
.image-left-text-right1 {
  background-color: #f1f1f1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10vh;
  margin-bottom: 10vh;
  padding: 5%;
  /* margin-right: 5%; */
}
.img-left1 {
  width: 70%;
  border-radius: 10px;
  box-shadow: 10px 10px 17px 0px rgba(0,0,0,0.18);
  -webkit-box-shadow: 10px 10px 17px 0px rgba(0,0,0,0.18);
  -moz-box-shadow: 10px 10px 17px 0px rgba(0,0,0,0.18);
}
.text-right {
  width: 35%;
  border-right: 4px solid #8f2a2b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.text-right h1 {
  width: 80%;
}
.text-right p {
  width: 65%;
  font-weight: 400;
  font-size: 18px;
}
.left-img-wrapper {
  z-index: 1;
  width: 40%;
}
/*the first video on the page*/
.welcome-vid-container {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  /* margin-top: 10vh; */
}
.video1 {
  width: 90%;
}
/*second div of image and text - clinical director's message*/
.image-right-text-left {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-top: 15vh;
  margin-bottom: 15vh;
  margin-left: 20vw;
}
.text-left {
  /* width: 35%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  position: relative;
  /* right: 30vw; */
  width: 35%;
  border-left: 4px solid #8f2a2b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.text-left h1 {
  width: 65%;
}
.text-left p {
  width: 65%;
  font-weight: 300;
  font-size: 18px;
}
.right-img-wrapper {
  width: 40%;
  position: relative;
  left: 30vw;
  z-index: 1;
}
.img-right {
  width: 70%;
  border-radius: 10px;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.233);
}
/* div containing the 2 training videos*/
.training-videos-container {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 15vh 10vw 0 10vw;
  /* position: relative;
  right: 8vw; */
}
.iframe-video {
  width: 600px;
  height: 600px;
}
.vp-center {
  align-items: flex-start !important;
}
.assessment-training-vid {
  border-radius: 10px;
  position: relative;
  top: 5vh;
}
.culture-training-vid {
  position: relative;
  width: 600px;
}
.trainingVid{
  width: 70%;
}
.vid-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.vid-wrapper p {
  margin-top: 3vh;
  font-weight: 400;
}
/*  upcoming trainings  */
.ppn-trainings-outer {
  width: 70%;
  background-color: #f1f1f1;
  margin: 10vh 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.ppn-trainings-outer h1 {
  margin-bottom: 10vh;
  margin-top: 5vh;
}
.ppn-trainings-outer h2 {
  border-bottom: 2px solid #8f2a2b;
  margin-bottom: 5vh;
}
.ppn-trainings-outer h5 {
  margin-bottom: 2vh;
}
.ppn-trainings-outer p {
  font-weight: 400;
}
.img-left2 {
  width: 75%;
  border-radius: 10px;
}
.left-img-wrapper2 {
  width: 60%;
}
.national-trainings p {
  text-align: center;
}
.utah-trainings {
  margin-bottom: 10vh;
}
.utah-trainings p {
  text-align: center;
}
.utah-trainings h5 {
  border-bottom: 1px solid #8f2a2b;
}
.monthly-clinicals {
  position: relative;
  right: 10px;
}
.monthly-clinicals p {
  text-align: center;
}
.text-training {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.training-columns {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
/* the div containin a link to addt'l resources*/
.addtl-resources {
  margin-top: 20vh;
  margin-bottom: 10vh;
}
.link-ppn {
  font-style: italic;
  text-decoration: none;
  color: #111921;
}
.link-ppn:hover {
  color: #8f2a2b;
  font-style: italic;
}
/*----------additional resources--------*/
.addl-outer {
  background-color: #f1f1f1;

}
.slides-container {
  background-color: #f1f1f1;
  position: relative;
  top: 10vh;
  margin-bottom: 15vh;
}

/* LEGAL PAGES  */

/* phone size */
.legal-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 5%;
}
.legal-item h3 {
  font-weight: bold;
}
.legal-item h5 {
  margin-left: 7%;
}
.legal-item h6 {
  margin-left: 10%;
}
.legal-item p {
  font-weight: 400;
}

/* large screen  */
@media screen and (min-width: 1440px) {
.legal-outer {
  /* width: 100vw; */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.legal-item {
  width: 40%;
}

.hrc-inner {
  margin-left: 20%;
}
}
.App {
  text-align: center;
  /* font-family: 'Open Sans', sans-serif; */
  -webkit-font-smoothing: subpixel-antialiased;
  /* font-family: 'Fredoka One', cursive; */
  /* font-family: 'Kaushan Script', cursive; */
  /* font-family: 'Sacramento', cursive; */
  /* font-family: 'Montserrat', sans-serif; */
  /* font-family: 'Poppins', sans-serif; */
  /* font-family: 'Libre Franklin', sans-serif; */
  font-family: 'Lato', sans-serif;
  font-weight: 200;
  box-sizing: border-box;
}
.nav-wrapper {
  font-size: 14px;
}
/* ClassName for <a> tags is "a" */
.a {
  text-decoration: none;
  color: #111921;
}
.a2 {
  text-decoration: none;
  color: #cfcfcf;
}
.a2:hover {
  color: #111921;
}

/* p {
  font-family: 'Montserrat', sans-serif;

} */

a.dropdown-item:hover {
  background-color: #cfcfcf;
}
.primary-btn {
  background-color: #7b0303;
  width: 125px;
  color: white;
  font-size: 14pt;
  border: none;
  border-radius: 20px;
  padding: 0.6%; 
  box-shadow: 4px 7px 16px -7px rgba(0,0,0,0.71);
}
.providers-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.provheader-container {
  background-color: rgb(233, 233, 233);
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.provheader-container h1 {
  font-size: 22px;
}
.provider-header-img {
  width: 50%;
}
.provmain-container {
  width: 100%;
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.provmain-container h1 {
  margin: 5% 0;
}
.prov-sidebyside {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.display-none {
  display: none;
}

.prov-text {
  background-color: rgba(211, 211, 211, 0.267);
  width: 80%;
  /* height: 228px; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  border-bottom: 5px;
  border-radius: 10px;
  border-style: solid;
  padding: 1rem;
  border-image: linear-gradient(to right, #7b0303, #cfcfcf) 1 50;
  font-weight: 400;
  font-size: larger;
}
.prov-main-img {
  width: 80%;
  margin-bottom: 2%;
}

.provben-container {
  background-color: #7b0303;
  color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5%;
  margin-bottom: 5%;
}
.benefits-list {
  background-color: white;
  width: 90%;
  color: black;
  font-size: 18pt;
  font-weight: 400;
  padding: 5% 10%;
  border-radius: 15px;
}

.provcta-container {
  background-color: rgb(233, 233, 233);
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
  padding: 2%;
}
.provcta-container p {
  font-weight: 400;
  font-size: 14pt;
}

@media screen and (min-width: 768px) {
  .provheader-container h1 {
    font-size: 28pt;
    width: 60%;
  }
  .provmain-container h1 {
    width: 60%;
  }
  .prov-sidebyside {
    width: 70%;
  }
}
@media screen and (min-width: 1440px) {
  .provheader-container h1 {
    font-size: 32pt;
    width: 45%;
  }
  .prov-sidebyside {
    width: 65%;
  }
  .provben-container h1 {
    margin-bottom: 5%;
  }
  .benefits-list {
    width: 55%;
  }
  .provcta-container {
    width: 49%;
  }
}

@media screen and (min-width: 1900px) {

.provheader-container h1 {
  width: 30%;
}
.header-img {
  width: 30%;
}
.provmain-container {
  margin-bottom: 5%;
  width: 75%;
}
.provmain-container h1 {
  margin: 5% 0;
  width: 65%;
}
.prov-sidebyside {
  display: flex;
  justify-content: space-around;
  width: 85%;
}
.prov-text {
  width: 73%;
  height: 228px;
}
.provmain-imgs {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.prov-main-img {
  width: 30%;
  /* height: 228px; */
}
.display-none {
  display: block;
  width: 30%;
}

.provben-container {
  width: 100%;
}
.benefits-list {
  line-height: 2;
}

.provcta-container {
  margin-bottom: 5%;
}
.provcta-container p {
  font-weight: 400;
}
}
.blog-post-img {
  width: 100%;
}
.blog-post-container {
  /* border: 1px solid orange; */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  margin-top: 5%;
}
.blog-post-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  /* border: 1px solid green; */
}
.blog-post-inner h1 {
  font-weight: bold;
  text-align: center;
}
.blog-post-inner p {
  font-weight: 500;
  text-align: center;
  /* i prefer left aligned but everyone else will like center ^^ */
  font-size: 15pt;
}
.blog-texts {
  text-align: left;
  font-weight: bold;
  padding-top: 3%;
  padding-left: 5%;
  padding-right: 2%;
}
.blog-content {
  margin-top: 5%;
}
.blog-content h5 {
  line-height: 1.75;
  font-weight: bold;
}
.blog-content p {
  text-align: left;
}
.blog-post-ul {
  font-weight: 400;
  font-size: 14pt;
}
.blog-post-ul h5 {
  font-weight: bold;
}
.blog-post-attributes p {
  line-height: 10%;
  font-size: 11pt;
  font-weight: 200;
}

  /* BLOG PAGE - WITH ALL THE CARDS OF THE POSTS */
  .blog-container {
    display: flex;
    justify-content: center;

  }
  .blog-inner {
    width: 90%;
    display: flex;
    justify-content: center;
    justify-content: center;
    margin-bottom: 10%;
    flex-wrap: wrap;
    /* border: 1px solid orange; */
  }
  .blog-card {
    width: 350px;
    display: flex;
    flex-direction: column;
    margin-top: 5%;
    border-radius: 10px;
    box-shadow: 10px 10px 17px 0px rgba(0,0,0,0.18);
    -webkit-box-shadow: 10px 10px 17px 0px rgba(0,0,0,0.18);
    -moz-box-shadow: 10px 10px 17px 0px rgba(0,0,0,0.18);
  }
  .blogImg{
    width: 100%;
    height: 250px;
    border-radius: 10px 10px 0 0;
  }
  .blog-texts {
    text-align: left;
    font-weight: bold;
    padding-top: 3%;
    padding-left: 5%;
    padding-right: 2%;
  }
  .blog-texts p {
    font-weight: 400;
  }
  .read-more-btn {
    border: none;
    background-color: #7b0303;
    color: black;
    width: 45%;
    border-radius: 50px;
  }
  .blog-references {
    padding-top: 20px;
    margin-bottom: -10px;
  }
  .blog-references p {
    margin: 2px;
    margin-bottom: -10px;
    font-weight: 400;
    font-size: 10pt;
    padding-left: 5%;
  }

  @media screen and (min-width: 1024px) {
    .blog-inner {
      justify-content: space-evenly;
    }
  }

@media screen and (min-width: 1375px) {
  /* BLOG PAGE - WITH ALL THE CARDS OF THE POSTS */
  .blog-container {
    display: flex;
    justify-content: center;

  }
  .blog-inner {
    width: 70%;
    display: flex;
    justify-content: center;
    justify-content: space-between;
    margin-bottom: 10%;
    flex-wrap: wrap;
    /* border: 1px solid orange; */
  }
  .blog-card {
    width: 350px;
    display: flex;
    flex-direction: column;
    margin-top: 5%;
    border-radius: 10px;
    box-shadow: 10px 10px 17px 0px rgba(0,0,0,0.18);
    -webkit-box-shadow: 10px 10px 17px 0px rgba(0,0,0,0.18);
    -moz-box-shadow: 10px 10px 17px 0px rgba(0,0,0,0.18);
  }
  .blogImg{
    width: 100%;
    height: 250px;
    border-radius: 10px 10px 0 0;
  }
  .blog-texts {
    text-align: left;
    font-weight: bold;
    padding-top: 3%;
    padding-left: 5%;
    padding-right: 2%;
  }
  .blog-texts p {
    font-weight: 400;
  }
  .read-more-btn {
    border: none;
    background-color: #7b0303;
    color: black;
    width: 45%;
    border-radius: 50px;
  }
  .blog-references {
    padding-top: 20px;
    margin-bottom: -10px;
  }
  .blog-references p {
    margin: 2px;
    margin-bottom: -10px;
    font-weight: 400;
    font-size: 10pt;
    padding-left: 5%;
  }


  /* ---- BLOG POST - INDIVIDUAL FULL PAGE POST---- */
  .blog-post-container {
    /* border: 1px solid orange; */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    margin-top: 5%;
  }
  .blog-post-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35%;
    /* border: 1px solid green; */
  }
  /* if we dont like the title, description, and img centered, then get rid of ai center above and the width on the image otherwise the image is pushed left */
  .blog-line {
    background-color: rgb(218, 218, 218);
    height: 1px;
    width: 100%;
  }
  .blog-post-inner h1 {
    font-weight: bold;
    text-align: left;
  }
  .blog-post-inner p {
    font-weight: 500;
    text-align: center;
    /* i prefer left aligned but everyone else will like center ^^ */
    font-size: 15pt;
  }
  .blog-post-attributes p {
    line-height: 10%;
    font-size: 11pt;
    font-weight: 200;
  }
  .blog-post-img {
    width: 60%;
    box-shadow: 10px 10px 17px 0px rgba(0,0,0,0.18);
    -webkit-box-shadow: 10px 10px 17px 0px rgba(0,0,0,0.18);
    -moz-box-shadow: 10px 10px 17px 0px rgba(0,0,0,0.18);
  }
  .blog-content {
    margin-top: 5%;
  }
  .blog-content h5 {
    line-height: 1.75;
    font-weight: bold;
  }
  .blog-content p {
    text-align: left;
  }
  .blog-post-ul {
    font-weight: 400;
    font-size: 14pt;
  }
  .blog-post-ul h5 {
    font-weight: bold;
  }

  .worst-day-content {
    text-align: center;
  }
}
