.providers-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.provheader-container {
  background-color: rgb(233, 233, 233);
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.provheader-container h1 {
  font-size: 22px;
}
.provider-header-img {
  width: 50%;
}
.provmain-container {
  width: 100%;
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.provmain-container h1 {
  margin: 5% 0;
}
.prov-sidebyside {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.display-none {
  display: none;
}

.prov-text {
  background-color: rgba(211, 211, 211, 0.267);
  width: 80%;
  /* height: 228px; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  border-bottom: 5px;
  border-radius: 10px;
  border-style: solid;
  padding: 1rem;
  border-image: linear-gradient(to right, #7b0303, #cfcfcf) 1 50;
  font-weight: 400;
  font-size: larger;
}
.prov-main-img {
  width: 80%;
  margin-bottom: 2%;
}

.provben-container {
  background-color: #7b0303;
  color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5%;
  margin-bottom: 5%;
}
.benefits-list {
  background-color: white;
  width: 90%;
  color: black;
  font-size: 18pt;
  font-weight: 400;
  padding: 5% 10%;
  border-radius: 15px;
}

.provcta-container {
  background-color: rgb(233, 233, 233);
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
  padding: 2%;
}
.provcta-container p {
  font-weight: 400;
  font-size: 14pt;
}

@media screen and (min-width: 768px) {
  .provheader-container h1 {
    font-size: 28pt;
    width: 60%;
  }
  .provmain-container h1 {
    width: 60%;
  }
  .prov-sidebyside {
    width: 70%;
  }
}
@media screen and (min-width: 1440px) {
  .provheader-container h1 {
    font-size: 32pt;
    width: 45%;
  }
  .prov-sidebyside {
    width: 65%;
  }
  .provben-container h1 {
    margin-bottom: 5%;
  }
  .benefits-list {
    width: 55%;
  }
  .provcta-container {
    width: 49%;
  }
}

@media screen and (min-width: 1900px) {

.provheader-container h1 {
  width: 30%;
}
.header-img {
  width: 30%;
}
.provmain-container {
  margin-bottom: 5%;
  width: 75%;
}
.provmain-container h1 {
  margin: 5% 0;
  width: 65%;
}
.prov-sidebyside {
  display: flex;
  justify-content: space-around;
  width: 85%;
}
.prov-text {
  width: 73%;
  height: 228px;
}
.provmain-imgs {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.prov-main-img {
  width: 30%;
  /* height: 228px; */
}
.display-none {
  display: block;
  width: 30%;
}

.provben-container {
  width: 100%;
}
.benefits-list {
  line-height: 2;
}

.provcta-container {
  margin-bottom: 5%;
}
.provcta-container p {
  font-weight: 400;
}
}