@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,300&display=swap');

.mhm-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 25vh;
}
.mhm-signup {
  width: 75%;
  height: 650px;
  /* margin-bottom: 50px; */
}

.blog-link {
  color: black;
}

/*---------scroll to top btn----------------*/
.to-top-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 25px;
  background-color: rgba(27, 27, 27, 0.849);
  color: white;
  /* background-color: white;
  color: black; */
  cursor: pointer;
  border: none;
  border-radius: 50%;
  z-index: 2;
}
.to-top-btn:hover {
  background-color: black;
}

/*-----------------------DISCLOSURES--------------------*/
/* .disclosures-container {

} */

.disclosures-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.disclosures-texts {

  text-align: left;
  /* background-color:; */
  max-width: 80vw;
}

/*--------------UNIversal FOOTER-------------*/
.uni-footer {
  background-color: white !important;
  padding: 2vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-logo {
 width: 20%;
 padding-right: 2%;
}
.footer-snippet {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 225px;
  font-size: 14px;
  font-weight: bold;
}
.footer-snippet-larger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 275px;
  font-size: 14px;
  font-weight: bold;
}
.footer-snippet > p:first-of-type, .footer-snippet-larger > p:first-of-type{
  position: relative;
  top: 6px;
  margin-right: 2%;
}
.email-hover-dark:hover {
  color: #8f2a2b;
}
.footer-icons {
  color: white;
  display: flex; 
  justify-content: center;
  width: 35px;
  height: 35px;
  /* border: 1px solid #8f2a2b; */
  background-color: #8f2a2b;
  border-radius: 50%;
}
.footer-icon {
  position: relative;
  top: 9px;
  font-size: 15px;
}
/* .socials-footer {
  width: 5%;
  font-size: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  bottom: 2px;
}
.social-f {
  color: #cfcfcf;
} */
.footer-line {
  height: 60px;
  width: 1px;
  background-color: #cfcfcf;
}
.whitespace {
  height: 10vh;
}
/* ---------------------TEAM---------------- */ 

.under-construction {
  width: 40%;
}
.teams-outer {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}
.teams-outer > h1 {
  margin-top: 5vh;
  margin-bottom: 5vh;
}
.teams-outer > h2 {
  margin-bottom: 3vh;
}
.team-inner h2 {
  position: relative;
  left: 6%;
}
.team-inner {
  width: 75vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.team-group {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.team-item {
  /* width: 33%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.team-item p {
  /* width: 350px; */
  font-weight: 400;
  font-size: 12pt;
}
.team-background {
  /* background-color: #8f2a2b; */
  color: #CFCFCF;
}
.team-img {
  height: 250px;
  width: 250px;
  margin: 15px;
  margin-right: 20px;
  border-radius: 50%;
}


.thumb__title {
  font-size: 25px;
  font-weight: 700;
  line-height: 1.33;
  text-align: center;
}
.thumb__subtitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.71429;
  font-style: italic;
  text-align: center;
}
.thumb-corporate__caption {
  margin-top: 10px;
}
.team-spacing {
  padding-top: 8vh;
  padding-bottom: 5vh;
}
.team-inner {
  padding-bottom: 3vh;
}
.team-inner > h2 {
  font-size: 40px;
}

/* .overlay {
  position: absolute;
  bottom: 16px;
  left: 20px;
  right: 0;
  background-color: #f7f7f7b7;
  overflow: hidden;
  width: 99%;
  height: 40px;
  transition: .5s ease;
  color: #111921;
}
.overlay > p {
  position: relative;
  left: 10px;
  font-weight: 300;
} */
.team-member-container {
  overflow: hidden;
}

.team-member-container:hover .overlay {
  height: 25%;
  overflow-x: hidden;
}
.open-button {
  background: none;
  border: none;
}
.exit-btn {
  background-color: white;
  border: none;
  height: 20px;
  width: 20px;
}

  .team-modal {
    animation: scaleUp .5s ease forwards;
  }
   /* .team-flex {
    animation: scaleBack .5s ease-in-out forwards;
  } */
  .inactive {
    animation: quickScaleDown 0s .1s linear forwards;
  }
  /* .dark-background {
    animation: fadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
  } */
  .inactive {
    animation: fadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }
  .inactive {
      animation: scaleDown .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
  }
  /* .team-flex {
    animation: scaleForward .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
  } */

@keyframes fadeIn {
  0% {
    background: rgba(255, 255, 255, 0.466);
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    background:rgba(0,0,0,.0);
  }
}

@keyframes scaleUp {
  0% {
    transform: translateY(1000px);
    opacity:0;
  }
  100% {
    transform:scale(1) translateY(0px);
    opacity:1;
  }
}

@keyframes scaleDown {
  0% {
    transform:scale(1) translateY(0px);
    opacity:1;
  }
  100% {
    transform:scale(.8) translateY(1000px);
    opacity:0;
  }
}

@keyframes scaleBack {
  0% {
    transform:scale(1);
  }
  100% {
    transform:scale(.85);
  }
}

@keyframes scaleForward {
  0% {
    transform:scale(.85);
  }
  100% {
    transform:scale(1);
  }
}

@keyframes quickScaleDown {
  0% {
    transform:scale(1);
  }
  99.9% {
    transform:scale(1);
  }
  100% {
    transform:scale(0);
  }
}
.team-modal {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: white;
  color: #111921;
  /* height: 350px; */
  width: 60%;
  border: none;
  z-index: 2 !important;
  position: fixed;
  /* bottom: 30%;
  left: 28%; */
  top: 22%;
  left: 18%;
  border-radius: 5px;
  box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.15);
  /* animation: fadeIn 0.5s ease-in-out; */
}
.team-modal p {
  font-size: 16px;
  font-weight: 400;
}
.dark-background {
  background: #00000080;
  z-index: 1 !important;
  width: 100%;
  height: 300vh;
  position: fixed;
  bottom: 0;
}
.hide {
  display: none;
}

.modal-img-container {
  border-right: 1px solid #111921 !important;
  margin-right: 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 250px;
}
.modal-img {
  border-radius: 50%;
  width: 235px;
  position: relative;
  right: 2%;
}
.modal-text {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2%;
}
.modal-text > h4 {
  font-style: italic;
  padding-bottom: 8%;
  font-family: 'Montserrat', sans-serif;
}
/*--------------------- PUBLIC SAFETY--------------------- */ 
.blurb-container {
  border: 2px solid orange;
  width: 35%;
  height: 400px;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  flex-wrap: wrap;
}
.blurb {
  width: 30%;
  border: 1px solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.liaison {
  background-color: transparent;
  display: inline-block;
  width: auto;
  max-width: 100%;
  border: 1px solid #e0e0e3;
  color: #CFCFCF;
  border-radius: 3px;
  padding: 12px 30px;
  font-size: 12px;
  line-height: 24px;
  font-family: "Libre Franklin", ;
  font-weight: 600;
  letter-spacing: .06em;
  transition: .33s all ease;
  white-space: normal;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
}
.liaison:hover {
  background-color: whitesmoke;
  color: #111921;
}
.button-parent {
  display: flex;
  justify-content: center;
  margin-bottom: 8vh;
  margin-top: 8vh;
}
.PS-Outer {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.PS-Left {
  background-color: #8f2a2b;
  color: #e4e4e4;
  width: 100%;
}
.PS-Left p {
  font-weight: 300;
}
.Left-inner > h1 {
  margin-top: 50px;
  font-size: 50px;
  font-weight: 300;
}
.Left-inner {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ps-pros {
  border-left: 4px solid #e4e4e4;
  color: #111921;
  color: #e4e4e4;
  z-index: 1;
  padding: 5vh .5vw 0vh 2.9vw;
}
.ps-para {
  width: 60vw;
  position: relative;
  /* left: 20vw; */
  font-size: 18px;
}
.ps-para > p {
  text-align: center;
}
.PS-list-outer {
  /* width: 75vw;
  position: relative;
  left: 12vw; */
  top: 50px;
  padding-bottom: 100px;
  display: flex;
  justify-content: space-around;
  position: relative;
  right: 10vw;
}
.PS-list-outer h4 {
  text-align: left;
  font-size: 30px;
  position: relative;
  /* top: 10px; */
}
.PS-List {
  text-align: left;
  font-weight: bolder;
  font-size: 20px;
  line-height: 35pt;
  position: relative;
  /* top: 10px; */
}
.fireFighter-img {
  width: auto;
  height: auto;
  
  /* box-shadow: 10px 10px 32px 0px rgba(0,0,0,0.5);
  -webkit-box-shadow: 10px 10px 32px 0px rgba(0,0,0,0.5);
  -moz-box-shadow: 10px 10px 32px 0px rgba(0,0,0,0.5); */
}
.fireFighter-img1 {
  width: 33.3%;
  height: auto;

  box-shadow: 10px 10px 32px 0px rgba(0,0,0,0.5);
-webkit-box-shadow: 10px 10px 32px 0px rgba(0,0,0,0.5);
-moz-box-shadow: 10px 10px 32px 0px rgba(0,0,0,0.5);
}
.fireTruck2 {
  width: 33.3%;
  height: auto;
  box-shadow: 10px 10px 32px 0px rgba(0,0,0,0.5);
-webkit-box-shadow: 10px 10px 32px 0px rgba(0,0,0,0.5);
-moz-box-shadow: 10px 10px 32px 0px rgba(0,0,0,0.5);
}

.ps-img-container {
  background-color: #8f2a2b;
  /* display: grid; */
}
.icon-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 215px;
  height: 215px;
  border: 2px solid #e4e4e4;
  border-radius: 50%;
}
.icon-item > p {
  text-align: center;
  /* color: #8f2a2b; */
}
.react-multi-carousel-item {
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
}
.icon {
  font-size: 30px;
}
.icon-light {
  color: #e4e4e4;
  font-size: 30px;
  text-align: center;
}
.ps-icon-para {
  font-size: 16px;
  text-align: center;
  width: 70%;
  /* color: #8f2a2b; */

}
.ps-icon-para > p {
  text-align: center;
  /* color: #8f2a2b; */

}
.ps-icon-para-rapid {
  font-size: 15px;
  text-align: center;
  width: 80%;
  /* color: #8f2a2b; */
}
.ps-icon-para-rapid > p {
  text-align: center;
}
/* .PS-Carousel {
  width: 80%;
  position: relative;
  margin-left: 10px;
} */
.ps-carousel-container {
  width: 100%;
  position: relative;
  left: 0px;
}


/* -------------------HUMANITARIAN------------------------ */
.hum-outer {
  background-color: #8f2a2b;
  color: #CFCFCF;
  text-align: center;
  padding-top: 30px;
  padding-left: 10vw;
}
.hum-para-container > p {
  text-align: center;
}
.hum-carousel-img {
  width: 75%;
  height: auto;
}
.hum-left-top {
  padding: 20px;
  position: relative;
  right: 5vw;
}
.hum-left-top > h1 {
  font-size: 60px;
  font-weight: 300;
}
.achf-img-container {
  padding: 10px;
  margin-bottom: 20px;
}
.ACHF {
  width: 40%;
}
.here {
  font-weight: 800;
  text-decoration: none;
  color: #CFCFCF;
}
.here:hover {
  font-size: 15px;
  color: #cfcfcf;
}
/* needed for the humanitarian image carousel, but it does effect all the other carousels. */
/* .react-multi-carousel-track {
  left: 80px
} */


/* ---------------------FAITH BASED------------------ */
/* .faith-inner {
  background-color: #8f2a2b;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
} */

.faith-inner {
  background-image: url('../images/faithBased.jpg');
  /* height: 100vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; */
  position: relative;
  isolation: isolate;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.faith-inner::before {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  background: linear-gradient(0deg, rgba(230, 230, 230, 0.719), rgba(230, 230, 230, 0.37) );
}

.faith {
  padding: 5vh;
  font-size: 50px;
  font-weight: 400;
  /* color: #b1b1b1; */
}
.faith-paragraphs-container{
  width: 55%;
  font-weight: bolder;
  border-left: 4px solid #8f2a2b;
  text-align: left;
  position: relative;
  left: 10%;
  top: 10vh;
}
.faith-paragraphs-container p {
  font-size: 18px;
  padding: 2vh;
  text-align: left;
  margin-left: 2%;
}
/*---------------ICONS---------------*/
.icon-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 85vw;
  flex-wrap: wrap;
  position: relative;
  right: 8vw;
}
.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  padding: 20px;
  width: 200px;
}
.icon > p {
  font-size: 20px;
  text-align: center;
}

.icon-circle {
  font-size: 30px;
  border: 1px solid #111921;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-circle:hover {
  background-color: white;
  border: 2px solid white;
}
.faith-button-parent {
  margin-bottom: 10vh;
  margin-top: 15vh;
  display: flex;
  justify-content: flex-start;
  position: relative;
  left: 12%;
}
.faith-liaison {
  background-color: whitesmoke;
  display: inline-block;
  width: auto;
  max-width: 100%;
  border: 1px solid #e0e0e3;
  color: #CFCFCF;
  border-radius: 3px;
  padding: 12px 30px;
  font-size: 12px;
  line-height: 24px;
  font-family: "Libre Franklin", ;
  font-weight: 600;
  letter-spacing: .06em;
  transition: .33s all ease;
  white-space: normal;
  text-transform: uppercase;
  text-align: center;
}
.faith-liaison:hover {
  background-color: transparent;
  color: #111921; 
}


/*-----------------PROFESSIONAL-------------------*/
.pro-inner {
  padding: 20px;
  text-align: left;
  background-image: url('../images/plantFlipped.jpg');
  height: 100vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  isolation: isolate;
  overflow: hidden;
}
.pro-inner::before {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  background: linear-gradient(0deg, rgba(230, 230, 230, 0.822), rgba(230, 230, 230, 0.521) );
}


.pro-container > h1 {
  padding-top: 40px;
  padding-left: 2%;
  padding-bottom: 20px;
  border-left: 4px solid #8f2a2b;
  margin-bottom: 0%;
}
.pro-para > p {
  margin-top: 0%;
  padding-top: 30px;
  padding-bottom: 80px;
  padding-left: 4%;
  font-weight: 600;
  border-left: 4px solid #8f2a2b;
}
.professional-contact {
  padding-left: 4%;
}
.liaison-dark {
  background-color: transparent;
  display: inline-block;
  width: auto;
  max-width: 100%;
  border: 1px solid rgb(22, 22, 22);
  color: rgb(22, 22, 22);
  border-radius: 3px;
  padding: 15px 35px;
  font-size: 16px;
  line-height: 24px;
  font-family: "Libre Franklin", ;
  font-weight: 800;
  letter-spacing: .06em;
  transition: .33s all ease;
  white-space: normal;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
}
.liaison-dark:hover {
  background-color: rgba(245, 245, 245, 0.582);
}
/*----------------------CORRECTIONAL FACILITIES-------------------*/
.corr-inner {
  background-image: url('../images/lockAndKey.jpg');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: unset;
  object-fit: cover;
  position: relative;
  isolation: isolate;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.corr-inner::before {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  background: linear-gradient(0deg, rgba(230, 230, 230, 0.932), rgba(230, 230, 230, 0.801) );
}
.correctional {
  padding: 20px;
  font-weight: 300;
  font-size: 50px;
}
.corr-para {
  border-left: 4px solid #8f2a2b;
  text-align: left;
  position: relative;
  left: 10%;
}
.corr-para > p {
  font-size: 20px;
  padding: 20px;
  font-weight: 500;
}
.button-parent-corr {
  /* margin-top: 15%; */
  margin-bottom: 100px;
  position: relative;
  /* left: 12%; */
}
.cor-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  padding-top: 20px;
  /* padding-left: 20px; */
  /* padding-right: 40px; */
  width: 250px;
}
.carousel-container-cor {
  width: 100vw;
  position: relative;
}

.cor-icon > p {
  font-size: 20px;
  text-align: center;
}
.icon-circle-cor {
  font-size: 30px;
  border: 1px solid #111921;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-circle-cor:hover {
  background-color: #111921;
  color: white;
}
/* K12 */
.k12-inner {
  background-color: #8f2a2b;
  /* text-align: center !important; */
  color: #b1b1b1;
}
.k12-inner > h1 {
  padding: 25px;
  font-size: 40px;
  font-weight: 300;
}
.k12-inner > p {
  /* text-align: center !important; */
  font-size: 20px;
  /* font-weight: 500; */
  padding-left: 25px;
  padding-right: 2px;
}
.k12 {
  /* text-align: center !important; */
  font-size: 20px;
  /* font-weight: 500; */
  padding-left: 25px;
  padding-right: 2px;
}
.k12 > p {
  text-align: center;
  font-weight: bolder;
}
.k12-img {
  max-width: 375px;
  padding: 30px;
}
.k12-liaison {
  padding: 40px;
}
.k12-paragraph {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75vw;
  /* margin-right: 150px; */
}

.k12-paragraph > p  {
  text-align: center;
  font-weight: bolderd;
}
/* ---------------------CORPORATIONS-------------------- */
/* .corp-outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
} */
.corp-inner {
  background-image: url('../images/officeSetting.jpg');
  background-size: cover;
  background-position: unset;
  object-fit: cover;
  position: relative;
  isolation: isolate;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.corp-inner::before {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  background: linear-gradient(0deg, rgba(230, 230, 230, 0.678), rgba(230, 230, 230, 0.801) );
}
.corp-inner > img {
  width: 80%;
  height: auto;
}
.corp-paragraph > h1 {
  margin-top: 5vh;
  font-size: 50px;
  font-weight: 300;
}
.corp-paragraph {
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: left;
  border-left: 4px solid #8f2a2b;
  margin-top: 10vh;
  padding: 0 20px 0 35px;
  position: relative;
  left: 5vw;
}
.corp-paragraph > p {
  text-align: center;
  font-size: 20px;
  text-align: left;
  font-weight: bolder;
}
.button-parent-corp {
  /* width: 35%; */
  display: flex;
  justify-content: center;
  margin-top: 8vh;
  margin-bottom: 10vh;
  position: relative;
  left: 7%;
}
.corp-inner > img:hover {
  -webkit-box-shadow: 0px 20px 35px -16px rgba(255, 255, 255, 0.65);
  -moz-box-shadow: 0px 20px 35px -16px rgba(255, 255, 255, 0.65);
  box-shadow: 0px 20px 20px -10px rgba(255, 255, 255, 0.65),
              0px -20px 20px -10px rgba(255, 255, 255, 0.65);
}
.box-shadow-slow {
  -webkit-transition: 1s ease;
  -moz-transition: 1s ease;
  -o-transition: 1s ease;
  transition: 1s ease;
}
/* --------------MILITARY--------------- */

.parallax-background {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.mili-inner {
  background-image: url('../images/fadedflag.jpg');
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
.mili-inner > h1 {
  padding: 40px 0px 40px 0px;
  font-size: 50px;
  font-weight: 300;
}
.mili-img {
  width: 406px;
  height: 406px;
  border-radius: 50%;
  margin-bottom: 50px;
}
.mili-paragraph {
  text-align: center;
  font-weight: 300;
  font-size: 18px
}
.mili-paragraph > p {
  text-align: center;
}
.button-parent-mili {
  padding: 40px 0px 100px 0px;
}
.liaison-mili {
  background-color: transparent;
  display: inline-block;
  width: auto;
  max-width: 100%;
  border: 1px solid rgb(31, 31, 31);
  color: rgb(31, 31, 31);
  border-radius: 3px;
  padding: 12px 30px;
  font-size: 12px;
  line-height: 24px;
  font-family: "Libre Franklin", ;
  font-weight: 600;
  letter-spacing: .06em;
  transition: .33s all ease;
  white-space: normal;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
}
.liaison-mili:hover{
  background-color: whitesmoke;
  color: #111921;
}
.mili-counters {
  display: flex;
  justify-content: space-evenly;
  width: 80vw;
}
.counter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  width: 200px;
  padding-bottom: 10px;
}
.counter > h2 {
  font-size: 35px;
}
.counter-icon {
  font-size: 40px;
  color: #8f2a2b
}
.CountUp{
  font-size: 40px;
  font-weight: 300;
}

/* --------------CONTACT--------------- */
.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: left;
  height: 75vh;
  margin-top: 50px;
  margin-bottom: 70px;
}
.contact-form > h1 {
  text-align: left;
}
.contact-details {
  margin-top: 45px;
  margin-bottom: 140px;
}
.contact-details > h5 {
  text-align: left;
}
.contact-details > h1 {
  text-align: left;
}
.socials {
  width: 30%;
  font-size: 32px;
  display: flex;
  justify-content: space-between;
}
.social {
  color: #8f2a2b;
}
.social:hover {
  color: #7b0303a4;
}
.contact-inner {
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  isolation: isolate;
  overflow: hidden;
}
.background {
  background-image: url('../images/handsin.jpg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  isolation: isolate;
  overflow: hidden;
}
.background::before {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.726), rgba(240, 240, 240, 0.418) );
}

.contact-input {
  width: 350px;
  min-height: 50px;
  border-radius: 5px;
  border: none;
  text-align:center;
}
.contact-input:focus {
  outline: 1px solid #8f2a2b;
}
.input-icons {
  position: absolute;
  margin-top: 12px;
  margin-right: 5px;
  color: #7b0303a4;
  min-width: 50px;
  font-size: 25px;
  text-align: center;
}
.form-btn {
  width: 150px;
  height: 50px;
  border-radius: 5px;
  border: none;
  background-color: #8f2a2b;
  color: #CFCFCF;  
}
.form-btn:hover {
  background-color: #CFCFCF;
  color: #8f2a2b;
}
/*-------------TRAININGS--------*/
.trainings-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
iframe {
  margin-bottom: 100px;
  width: 100vw;
}
.trainings-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.trainings-inner h1 {
  margin-top: 10vh;
}
.courses-container {

  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.training-soon {
  width: 50%;
  margin-top: 10%;
}

/*------------- RESOURCES -----------*/
.hotline-resource-container {
  background-color: white;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
}
.hrc-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 5%;
  font-size: 14pt;
  font-weight: 400;
}

.resource-outer {
  margin-top: 5vh;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
}
#h3 {
  margin-top: 10vh;
  font-size: 50px;
}
.resource-input {
  margin-top: 7vh;
  width: 40vw;
}
.card-container {
  margin-top: 8vh;
  width: 65%;
}

.link2 {
  text-decoration: none;
  color: #CFCFCF;
}
.link2:hover {
  color: #111921;
}
.resources {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  flex-wrap: wrap;
  margin-bottom: 100px;
}
.rsc-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.line {
  background-color: rgb(218, 218, 218);
  height: 1px;
  width: 100%;
  margin-top: 15px;
}

.airbb {
 display: flex;
 justify-content: space-between;
 align-items: center;
 padding: 0;
}
.airbb2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
 }
.resourceImg {
  width: 275px;
  height: 180px;
  border-radius: 15px;
  box-shadow: 10px 10px 17px 0px rgba(0,0,0,0.18);
  -webkit-box-shadow: 10px 10px 17px 0px rgba(0,0,0,0.18);
  -moz-box-shadow: 10px 10px 17px 0px rgba(0,0,0,0.18);
}
.airbb-texts {
  width: 600px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  font-weight: bolder;
  /* padding-top: 4vh; */
}
.airbb-texts > h4 {
  font-weight: 900;
  color: #8f2a2b;
}
.airbb-texts p {
  font-size: 12px;
}
.airbb-texts > p:first-of-type {
  font-size: 14px;
}
.airbb-btn {
  border: none;
  border-radius: 50px;
  background-color: #8f2a2b;
  color: rgb(255, 255, 255);
}


/*----------- TIPS --------*/

ol {
  text-align: left;
  line-height: 25pt;
}
ul {
  text-align: left;
  line-height: 25pt;
}
.LI {
  font-weight: bolder;
}
.tips-container {
  margin-top: 10vh;
}
.tips-container h1 {
  margin-top: 2vh;
  font-size: 40px;
  font-weight: bolder;
  text-align: center;
}
.CIT {

  font-weight: 400;
}
.CIT h5:first-of-type {
margin: 5vh 10vw 5vh 0;
} 
.CIT h5 {
  font-weight: bolder;
}
.CIT p {
  margin-left: 5vw;
  line-height: 15pt;
}
.CRP {
  margin-top: 12vh;
  text-align: left;
  font-weight: 400;

}
.CRP h2 {
  margin-left: 5vw;
}
.phones {
  text-align: left;
  margin:  12vh 2vw 10vh 5vw;
}
.li-phone {
  font-weight: 300;
}
.red-dec-border {
  /* background: #F2F2F2; */
  border-top: 2px solid #8f2a2b;
  border-bottom: 2px solid #8f2a2b;
  padding: 2em 0 0.5em;
  position: relative;
  margin-bottom: 10vh;
}
.in-need {
  margin-top: 5vh;
  border: 1px solid black;
}
/* .red-dec-border::after {
  background: white;
  border: 2px solid #8f2a2b;
  border-radius: 50%;
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  left: 50%;
  margin-left: -12px;
  position: absolute;
  top: -14px;
  transform: rotate(45deg);
} */
.tips-header {
  background: url('../images/ogdenPD.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.tips-header2 {
  background: url('../images/ogdenArch.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 10vh;
  padding-top: 5vh;
}

.tips-header3 {
  background: url('../images/officeHands2.jpg');
  /* background-color: #F6E9E8; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 10vh;
  margin-bottom: 10vh;
  padding-top: 5vh;
}
.tips-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.red-link {
  text-decoration: none;
  font-style: italic;
  color: #8f2a2b
}
.safeUt {
  width: 40%;
}
.clearfield-logo-tips {
  max-width: 35%;
}
.rtt {
  width: 90%;
}
.reactions-to-trauma {
  border: 1px solid black;
  max-width: 99vw;
}
.reactions-to-trauma li {
  list-style-type: disc;
}
table,
th,
td {
    border: 1px solid black !important;
    text-align: left;
  
}
table {
  max-width: 93vw;
  margin-left: 3%;
  margin-bottom: 5vh;
}
th {
  font-size: 10px;
}
td {
  font-size: 12px;
  padding: 3px;
}

/*-----SURVEYS-------*/

.survey {
  text-align: left;
  width: 90vw !important;
}

#surveyResult { 
  width: 60%;
  margin-left: 5vw;
}

/*--------- PPN WELCOME PAGE -----------*/
.conf-mail {
  display: block;
  position: relative;
  border: none;
  padding: 4px;
  border-radius: 5px;
  font-weight: 300;
  color: white;
  background-color: #7b0303;
}
.conf-mail::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: rgba(255,255,255,0.4);
	-webkit-transition: none;
  -moz-transition: none;
  transition: none;
}
.conf-mail:hover::after {
  width: 120%;
  background-color: rgba(255,255,255,0);
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
/* this is for the flyer

.conference-flyer {
  width: 35vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flyer {
  background-position: center;
  background-repeat: no-repeat;
  width: 15vw;
}
.download {
  width: 50%;
  margin-bottom: 5%;
}
.flyer-texts p {
  font-weight: 300;
  margin-bottom: 5%;
}
.flyer-texts a {
  color: black;
}
.flyer-texts {
  display: flex;
  flex-direction: column;
  align-items: center;
} */
.providers {
  background-color: orange;
}
/*contains the whole page minus the nav bar*/
.ppn-wrapper {
  /* height: 1600px; */
  /* background-color: #f1f1f1; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
/*contains the two divs that make up the header*/
.ppn-header-wrapper {
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0;
}
/* below is the translucent overlay in the header*/
.ppn-header {
  /* background-color: #b1b1b1c2; */
  /* background-color: #8f2a2ab2; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  /* this height is going to be not good being a hard coded px. make it so it's always the same height as the image next to it.*/
  /* height: 458px; */
  z-index: 1;
}
/*the image contained in the header*/
.header-img {
  width: 40%;
  position: relative;
  /* right: 5%; */
}
/*first div of image and text - welcome paragraph*/
.image-left-text-right1 {
  background-color: #f1f1f1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10vh;
  margin-bottom: 10vh;
  padding: 5%;
  /* margin-right: 5%; */
}
.img-left1 {
  width: 70%;
  border-radius: 10px;
  box-shadow: 10px 10px 17px 0px rgba(0,0,0,0.18);
  -webkit-box-shadow: 10px 10px 17px 0px rgba(0,0,0,0.18);
  -moz-box-shadow: 10px 10px 17px 0px rgba(0,0,0,0.18);
}
.text-right {
  width: 35%;
  border-right: 4px solid #8f2a2b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.text-right h1 {
  width: 80%;
}
.text-right p {
  width: 65%;
  font-weight: 400;
  font-size: 18px;
}
.left-img-wrapper {
  z-index: 1;
  width: 40%;
}
/*the first video on the page*/
.welcome-vid-container {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  /* margin-top: 10vh; */
}
.video1 {
  width: 90%;
}
/*second div of image and text - clinical director's message*/
.image-right-text-left {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-top: 15vh;
  margin-bottom: 15vh;
  margin-left: 20vw;
}
.text-left {
  /* width: 35%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  position: relative;
  /* right: 30vw; */
  width: 35%;
  border-left: 4px solid #8f2a2b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.text-left h1 {
  width: 65%;
}
.text-left p {
  width: 65%;
  font-weight: 300;
  font-size: 18px;
}
.right-img-wrapper {
  width: 40%;
  position: relative;
  left: 30vw;
  z-index: 1;
}
.img-right {
  width: 70%;
  border-radius: 10px;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.233);
}
/* div containing the 2 training videos*/
.training-videos-container {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 15vh 10vw 0 10vw;
  /* position: relative;
  right: 8vw; */
}
.iframe-video {
  width: 600px;
  height: 600px;
}
.vp-center {
  align-items: flex-start !important;
}
.assessment-training-vid {
  border-radius: 10px;
  position: relative;
  top: 5vh;
}
.culture-training-vid {
  position: relative;
  width: 600px;
}
.trainingVid{
  width: 70%;
}
.vid-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.vid-wrapper p {
  margin-top: 3vh;
  font-weight: 400;
}
/*  upcoming trainings  */
.ppn-trainings-outer {
  width: 70%;
  background-color: #f1f1f1;
  margin: 10vh 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.ppn-trainings-outer h1 {
  margin-bottom: 10vh;
  margin-top: 5vh;
}
.ppn-trainings-outer h2 {
  border-bottom: 2px solid #8f2a2b;
  margin-bottom: 5vh;
}
.ppn-trainings-outer h5 {
  margin-bottom: 2vh;
}
.ppn-trainings-outer p {
  font-weight: 400;
}
.img-left2 {
  width: 75%;
  border-radius: 10px;
}
.left-img-wrapper2 {
  width: 60%;
}
.national-trainings p {
  text-align: center;
}
.utah-trainings {
  margin-bottom: 10vh;
}
.utah-trainings p {
  text-align: center;
}
.utah-trainings h5 {
  border-bottom: 1px solid #8f2a2b;
}
.monthly-clinicals {
  position: relative;
  right: 10px;
}
.monthly-clinicals p {
  text-align: center;
}
.text-training {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.training-columns {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
/* the div containin a link to addt'l resources*/
.addtl-resources {
  margin-top: 20vh;
  margin-bottom: 10vh;
}
.link-ppn {
  font-style: italic;
  text-decoration: none;
  color: #111921;
}
.link-ppn:hover {
  color: #8f2a2b;
  font-style: italic;
}
/*----------additional resources--------*/
.addl-outer {
  background-color: #f1f1f1;

}
.slides-container {
  background-color: #f1f1f1;
  position: relative;
  top: 10vh;
  margin-bottom: 15vh;
}

/* LEGAL PAGES  */

/* phone size */
.legal-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 5%;
}
.legal-item h3 {
  font-weight: bold;
}
.legal-item h5 {
  margin-left: 7%;
}
.legal-item h6 {
  margin-left: 10%;
}
.legal-item p {
  font-weight: 400;
}

/* large screen  */
@media screen and (min-width: 1440px) {
.legal-outer {
  /* width: 100vw; */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.legal-item {
  width: 40%;
}

.hrc-inner {
  margin-left: 20%;
}
}